<template>
    <div class="cocktail-background">
        <div class="cocktails-header">
            <img :src="require('@/assets/cocktails/home.png')" @click="goToHomeRoute">
            <span class="breadcrumb-text" @click="goToCocktailsHomeRoute"> {{ (cocktail.cocktailBase==='WINE'? 'Sagria cocktails ' : 'Spirit cocktails') }}
            </span>
            <font-awesome-icon color="#d9a14a" icon="chevron-right" />
            <span class="home-text"> {{ cocktail.name }}</span>
        </div>
        <div class="cocktail-image">
            <img height="460px" :src="getCocktailImage()">
        </div>
        <div class="cocktail-title">
            {{ cocktail.name }}
        </div>
        <div class="cocktail-subtitle">
            {{ cocktail.description }}
        </div>
        <v-row class="pt-3 px-4 mx-0 my-0">
            <v-col cols="6" class="pl-3">
                <div class="section-title">
                    Συστατικά
                </div>
                <v-divider class="mt-4" />
                <div class="quantity-section">
                    <span class="quantity-text">
                        Αρ. Κοκτέιλ
                    </span>
                    <v-btn
                        class="minus-button"
                        fab
                        large
                        outlined
                        dark
                        color="white"
                        @click="decreaseQuantity()"
                    >
                        <font-awesome-icon icon="minus" />
                    </v-btn>
                    <span class="quantity">
                        {{ quantity }}
                    </span>
                    <v-btn
                        class="plus-button"
                        color="white"
                        outlined
                        fab
                        dark
                        large
                        @click="increaseQuantity()"
                    >
                        <font-awesome-icon icon="plus" />
                    </v-btn>
                </div>
                <v-divider class="mb-4" />
                <div class="ingredients">
                    <v-row v-for="(ingredient, i) in cocktail.ingredients" :key="i" class="ingredient">
                        <v-col cols="5" class="ingredient-quantity"> {{ (ingredient.fractionedQuantity? ingredient.fractionedQuantity : ingredient.customQuantity) + ingredient.customMeasuringUnit }}  </v-col>
                        <v-col cols="7" class="ingredient-text"> {{ ingredient.name }} </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6" class="pl-5 mx-0">
                <div class="section-title">
                    Εκτέλεση
                </div>
                <div class="execution-text pr-5">
                    {{ cocktail.executionDescription }}
                </div>
                <v-row class="mt-5 mx-0">
                    <v-col class="px-0" cols="3">
                        <qrcode-vue :value="cocktail.qrLink" :size="100" level="H" />
                    </v-col>
                    <v-col cols="9" class="qr-text"> ΣΚΑΝΑΡΕ ΚΑΙ ΔΕΣ ΤΗ ΣΥΝΤΑΓΗ ΣΤΟ ΚΙΝΗΤΟ ΣΟΥ </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="mb-4 mx-5" />
        <v-row class="mx-0">
            <v-col cols="12" class="mt-2 mx-0 px-0">
                <div class="carousel-title"> Φτιάξε το κοκτέιλ σου με αυτά τα υλικά! </div>
            </v-col>
        </v-row>
        <v-row class="mx-0 px-0 my-0">
            <v-col cols="12" class="mt-4">
                <vueper-slides
                    slide-multiple
                    :bullets="false"
                    :visible-slides="3"
                    fixed-height="320px"
                    class="no-shadow"
                    :dragging-distance="50"
                    :arrows-outside="false"
                >
                    <vueper-slide
                        v-for="(recommendation, i) in recommendations"
                        :key="i"
                        :max-width="'10px'"
                        @click.native="goToProductRoute(recommendation)"
                    >
                        <template #content>
                            <div class="text-center">
                                <img height="240px" :src="getProductImage(recommendation)">
                            </div>
                            <div class="pt-2 carousel-slide-title">{{ recommendation.commercialName }}</div>
                            <div class="carousel-slide-subtitle">{{ getDescription(recommendation) }}</div>
                            <div class="carousel-slide-subsubtitle">{{ getBrand(recommendation) }}</div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import cocktailService from '@/services/cocktails-service'
    import QrcodeVue from 'qrcode.vue'
    import config from '@/mixins/config'

    export default {
        components: {
            VueperSlides,
            VueperSlide,
            QrcodeVue
        },
        mixins: [config],
        data() {
            return {
                image: require('@/assets/cocktails/white-sangria.png'),
                quantity: 1,
                cocktail: {},
                recommendations: []
            }
        },
        watch: {
            quantity() {
                this.cocktail.ingredients.forEach(ingredient => {
                    this.ingredientMeasurements(ingredient)
                })
            }
        },
        created() {
            this.fetchCocktail()
        },
        methods: {
            async fetchCocktail() {
                this.cocktail = await cocktailService.get(this.$route.params.id)
                this.recommendations = this.recommendations.concat(this.cocktail.recommendedSpirits, this.cocktail.recommendedWines)
                this.cocktail.ingredients.forEach(ingredient => {
                    this.ingredientMeasurements(ingredient)
                })
            },
            getDescription(recommendation) {
                if (recommendation.color) {
                    return recommendation.color + ' ' + recommendation.bottleVolume
                } else {
                    return recommendation.category + ' ' + recommendation.bottleVolume
                }
            },
            getBrand(recommendation) {
                if (recommendation.winery) {
                    return recommendation.winery
                } else {
                    return recommendation.distillingCompany
                }
            },
            ingredientMeasurements(ingredient) {
                ingredient.customQuantity = Math.round(ingredient.quantity * this.quantity * 100) / 100
                if (ingredient.measuringUnit === 'ml') {
                    if (ingredient.customQuantity >= 1000) {
                        ingredient.customQuantity /= 1000
                        ingredient.customMeasuringUnit = 'l'
                    } else {
                        ingredient.customMeasuringUnit = 'ml'
                    }
                } else if (ingredient.measuringUnit === 'γραμ') {
                    if (ingredient.customQuantity >= 1000) {
                        ingredient.customQuantity /= 1000
                        ingredient.customMeasuringUnit = 'κιλά'
                    } else {
                        ingredient.customMeasuringUnit = 'γραμ'
                    }
                } else {
                    ingredient.fractionedQuantity = this.fraction(ingredient.customQuantity)
                    ingredient.customMeasuringUnit = ingredient.measuringUnit
                }
            },
            fraction(number) {
                if (number % 1 !== 0) {
                    let int = ''
                    if (number > 1) {
                        int = Math.floor(number / 1)
                        number = Math.round((number % 1) * 100) / 100
                    }
                    let denominator = Math.pow(10, number.toString().length)
                    let numerator = number * denominator
                    const divisor = this.gcd(numerator, denominator)
                    numerator /= divisor
                    denominator /= divisor
                    number = (int + ' ' + Math.floor(numerator) + '/' + Math.floor(denominator))
                }
                return number
            },
            increaseQuantity() {
                if (this.quantity < 20) {
                    this.quantity++
                }
            },
            decreaseQuantity() {
                if (this.quantity > 1) {
                    this.quantity--
                }
            },
            goToHomeRoute() {
                this.$router.push('/home')
            },
            goToCocktailsHomeRoute() {
                this.$router.push('/cocktails')
            },
            goToProductRoute(product) {
                if (product.category) {
                    this.$router.push('/spirit-mastery/' + product.id)
                } else {
                    this.$router.push('/wine-mastery/wines/' + product.id)
                }
            },
            getCocktailImage() {
                if (this.cocktail.image) {
                    return this.config.images + this.cocktail.image.relativePath + '/' + this.cocktail.image.fileName
                } else {
                    return require('@/assets/cocktails/white-sangria.png')
                }
            },
            getProductImage(product) {
                if (product.avatarPhotos) {
                    return this.config.images + product.avatarPhotos[0].relativePath + '/' + product.avatarPhotos[0].fileName
                } else {
                    return require('@/assets/cocktails/white-sangria.png')
                }
            },
            gcd(a, b) {
                if (b < 0.0000001) {
                    return a // Since there is a limited precision we need to limit the value.
                }
                return this.gcd(b, Math.floor(a % b)) // Discard any fractions due to limitations in precision.
            }
        }
    }
</script>

<style>
    .vueperslides__arrow{
        width: 82px;
        height: 82px;
        border-radius: 41px;
        border: 2.51px solid #ffffff;
    }
</style>

<style scoped>
    .cocktails-header {
        padding-top: 20px;
        padding-left: 20px;
    }
    .breadcrumb-text {
        font-size: 23px;
        letter-spacing: 0px;
        color: #d9a14a;
        font-weight: 300;
        font-family: "C FAsty Std";
    }
    .minus-button{
        margin-left: 180px;
        margin-right: 20px;
        border-width: medium;
    }

    .plus-button{
        margin-left: 20px;
        border-width: medium;
    }
    .v-divider {
        height: 2px;
        background-color: #ffffff;
    }
    .cocktail-background {
        background: url('~@/assets/cocktails/background_cocktails.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .home-text {
        font-size: 23px;
        letter-spacing: 0px;
        color: #fffdf6;
        font-weight: bold;
        font-family: "C FAsty Std";
    }

    .cocktail-image {
        margin-top: 0px;
        text-align: center;
    }

    .cocktail-title {
        font-size: 74px;
        line-height: 60px;
        color: #fffdf6;
        font-weight: 400;
        font-family: "Source Serif Pro";
        text-align: center;
    }

    .cocktail-subtitle {
        margin-top: 30px;
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        color: #e6e1d7;
        font-weight: 300;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .section-title{
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 24px;
        color: #d9a14a;
        font-weight: bold;
        font-family: "C FAsty Std";
    }

    .quantity-text{
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        font-weight: 500;
        font-family: "C FAsty Std";
    }

    .quantity {
        font-size: 28px;
        line-height: 22px;
        color: #ffffff;
        font-weight: 500;
        font-family: "C FAsty Std";
        vertical-align: middle;
    }

    .ingredient-quantity {
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 36px;
        color: #d9a14a;
        font-weight: bold;
        font-family: "C FAsty Std";
        text-align: right;
    }

    .ingredient-text {
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 28px;
        color: #e6e1d7;
        font-weight: bold;
        font-family: "C FAsty Std";
    }

    .execution-text {
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 40px;
        color: #e6e1d7;
        font-weight: 100;
        font-family: "C FAsty Std";
        margin-top: 15px !important;
    }

    .qr-text {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 26px;
        color: #d9a14a;
        font-weight: bold;
        font-family: "C FAsty Std";
        padding-right: 110px;
    }

    .carousel-title {
        font-size: 36px;
        letter-spacing: -1px;
        line-height: 24px;
        color: #fffdf6;
        font-weight: bold;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .carousel-slide-title {
        font-size: 20px;
        letter-spacing: 0px;
        line-height: 19px;
        color: #d9a14a;
        font-weight: bold;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .carousel-slide-subtitle {
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 16px;
        color: #fffdf6;
        font-weight: 300;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .carousel-slide-subsubtitle {
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 16px;
        color: #d1a679;
        font-weight: 300;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .ingredients {
        overflow-y: auto;
        overflow-x: hidden;
        height: 520px;
    }

    .execution-text {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 400px;
    }
</style>
