<template>
    <div>
        <div v-if="regions" class="container">
            <vueper-slides
                :bullets="false"
                :arrows="regions.length > 3"
                :infinite="false"
                :touchable="false"
                slide-multiple
                fixed-height="210px"
                class="no-shadow"
            >
                <vueper-slide
                    v-for="(bucket, index) in regionBucket"
                    :key="index"
                >
                    <template #content>
                        <!--:style="`--font-size:${fontSize}px`-->
                        <div class="carousel-container">
                            <div
                                v-for="region in bucket"
                                :key="region.id"
                                @click="goToRegion(region)"
                            >
                                <region-carousel-card :region="region" />
                            </div>
                        </div>
                    </template>
                </vueper-slide>
                <template #arrow-left>
                    <img :src="require('@/assets/wine-roads/arrow-previous.png')" alt="previous button">
                </template>
                <template #arrow-right>
                    <img :src="require('@/assets/wine-roads/arrow-next.png')" alt="next button">
                </template>
            </vueper-slides>
        </div>
    </div>
</template>
<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import RegionCarouselCard from '@/components/wine-roads/RegionCarouselCard.vue'
    export default {
        components: {
            VueperSlides,
            VueperSlide,
            RegionCarouselCard
        },
        props: {
            regions: {
                type: Array,
                required: true
            },
            bucketSize: {
                type: Number,
                required: false,
                default: 5
            }
        },
        data() {
            return {
                regionBucket: []
            }
        },
        computed: {
            fontSize() {
                let fontSize = 30
                this.regions.forEach(region => {
                    if (region.name.length >= 13) {
                        fontSize = 25
                    }
                })
                return fontSize
            }
        },
        watch: {
            regions: {
                immediate: true,
                handler(newVal) {
                    this.preprocessRegions()
                }
            }
        },
        created() {
            this.preprocessRegions()
        },
        methods: {
            preprocessRegions() {
                let index = 1
                let bucket = []
                for (const region of this.regions) {
                    bucket.push(region)
                    if (index % this.bucketSize === 0) {
                        this.regionBucket.push(bucket)
                        bucket = []
                    }
                    index++
                }
                if (bucket.length > 0) {
                    this.regionBucket.push(bucket)
                }
            },
            goToRegion(region) {
                this.$router.push({
                    path: `/wine-roads/${region.step}/${region.id}`
                })
            }
        }

    }
</script>

<style lang="scss" scoped>

.container {
    max-width: 100%;
    height: 210px;
    background-color: #7f2f2d;
}
.carousel-container {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // font-size: var(--font-size);
    font-size:  30px;
}

</style>
