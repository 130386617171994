<template>
    <div :class="`container theme-${theme}`">
        <div v-if="theme === 'black'" class="ab_image">
            <img src="@/assets/wine-roads/wine_and_spirits_mastery.png">
        </div>
        <div class="title">{{ title }}</div>
        <div class="text"> {{ text }} </div>
        <div class="arrow-image">
            <img v-if="theme === 'black' || theme === 'white'" class="arrow" src="@/assets/common/tile-dark-arrow.png">
            <img v-else class="arrow" src="@/assets/common/tile-light-arrow.png">
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                default: ''
            },
            theme: {
                type: String,
                default: 'black'
            },
            text: {
                type: String,
                default: ''
            }
        }
    }
</script>
<style scoped>
.container {
    height: 100%;
    width: 100%;
    position: relative;
    padding-left: 2rem;
    padding-top: 1.4rem;
    font-family: CFABold;
}

.title {
    padding-top: .5rem;
    padding-bottom: 1.7rem;
    font-size: .8rem;
}

.text {
    font-size: 1.6rem;
    padding-right: 2.2rem;
    line-height: 1.5rem;
    position: absolute;
    width: 20vw;
}

.theme-black {
    background: #403c3a;
}

.theme-white {
    background: #f2f1ed;
    color: #969593;
}

.theme-white .text {
    color: #bf8c40;
}

.theme-red {
    background: #792a2a;
    color: #c39646;
}

.theme-red .text {
    color: #edede3;
}

.theme-yellow {
    background: #bb7d34;
    color: #f1c580;
}

.theme-yellow .title {
    color: #fff;
}

.theme-green {
    background: #72725b;
    color: #fff;
}

.theme-green .title {
    color: #f1c57f;
}

.arrow-image {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    cursor: pointer;
}
.ab_image {
    position: absolute;
    left: .4rem;
    top: .3rem;
}
.ab_image img {
    height: 5rem;
}

@media (min-width: 1080px) and ( orientation: landscape) {
    .container {
        min-height: 150px;
    }
    .text {
        position: absolute;
        bottom: 1.5rem;
    }
}

@media (max-width: 1079px) {
    .container {
        min-height: 150px;
    }
    .text {
       font-size: 1.3rem;
       padding-right: 1.5rem;

    }
    .ab_image img {
        height: 4rem;
    }
    .arrow-image img {
        height: 3rem;
    }
}

@media (max-width: 769px) {
    .container {
        min-height: 150px;
    }
    .text {
       font-size: 1rem;

    }
    .ab_image img {
        height: 2.5rem;
    }
    .arrow-image img{
        height: 2.5rem;
    }
    .arrow-image {
        right: 1rem;
        bottom: 1.5rem;
    }
}
</style>
