import router from '@/router'
import store from '../store'

const idler = {
    interval: 1 * 60 * 1000,
    timer: null,
    reset() {
        clearInterval(this.timer)

        if (process.env.VUE_APP_DISABLE_TIMEOUT !== 'true') {
            this.timer = setInterval(() => {
                store.dispatch('hideKeyboard')
                router.push({ path: '/' }).catch(() => { })
            }, this.interval)
        }
    }
}

export default idler
