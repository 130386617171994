module.exports = {
    publicPath: '/app',
    chainWebpack: config => {
        config.plugin('prefetch').tap(options => {
            options[0].as = (entry) => {
                if (/\.css$/.test(entry)) return 'style'
                if (/\.woff$/.test(entry)) return 'font'
                if (/\.png$/.test(entry)) return 'image'
                if (/\.jpg$/.test(entry)) return 'image'
                return 'script'
            }
            options[0].include = 'allAssets'
            return options
        })
    }
}
