import restAPI from '@/utils/rest-api'

class CocktailService {
    getAll(requestData, cancelToken) {
        const filter = requestData.filter || {}
        const sort = requestData.sort || []
        const range = (requestData.range) ? JSON.stringify(requestData.range) : ''
        const url = `/cocktails?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&range=${range}`
        return restAPI.get(encodeURI(url), cancelToken)
    }

    get(id) {
        return restAPI.get(`/cocktails/${id}`)
    }
}
export default new CocktailService()
