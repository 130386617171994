<template>
    <div class="cocktail-background">
        <div class="cocktails-header">
            <img :src="require('@/assets/cocktails/home.png')" @click="goToHomeRoute">
            <span class="home-text"> Sagria cocktail</span>
        </div>
        <div class="cocktails-title">
            <div class="cocktails-type">
                Sangria
            </div>
            <div class="cocktails-type-subtitle">
                Cocktails
            </div>
        </div>
        <div class="secondary-text-box">
            <span class="secondary-text"> Φτιάξε το τέλειο σανγκρία κοκτέιλ </span>
        </div>
        <div class="carousel-container">
            <carousel-3d
                :controls-visible="true"
                :perspective="0"
                :space="500"
                :display="3"
                :count="slides"
                :gap="20"
                height="800px"
                width="380px"
                controls-next-html="<img alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAxCAYAAADNyhlBAAAACXBIWXMAAAsSAAALEgHS3X78AAABgElEQVR4nM3YsUvDQBgF8KgoIqHQRVAoXdwsOJSCf4iTLkXnrqIgTroWBC1FC+KgaIdSlw6iSAc3/x251e/DBA7B8wXed2ngrfm1yd3l3SWJdzn3tSjpSvaSWJeHfmZpx0CXJX0PzXNQBpqnYwVfBFA7XG7aknwA+JFkjo1vSaYAfmyBN0D8VDLPxjclrwB+ZoFvgPi5zn0L/AXAuxZ4TTKZdbynCxEbX5OMAbxvga+C+I0ktcCHAH5ngVclTyBescAfAFx/YJWNp9m/mml8pOODja+4cInIM7bA/2sweZ51QbLAkSYzscB/t9QQXmfj+s7fAPyeierjvor6uAsMMB3d6yxU5/N11ClVYBEZMtEKiPKWTYd/pR7LQHnfZfdTBkZloGj3oqG1Aiin8Dm84l4y0TqI8kq9w/dQpaC6a1xgoQ3JO4hytqoO35yfMNEmiPKOIxx+AHPIRLdBlHvkJDccREczOJXcBtB9Ourhf3WmthkawHfNUQ9fypbAHWvrGxIqX9vzx7n5AAAAAElFTkSuQmCC'/>"
                controls-prev-html="<img alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAxCAYAAADNyhlBAAAACXBIWXMAAAsSAAALEgHS3X78AAABiklEQVR4nMXYMUsDQRQE4KhEghyBNAGFkMZOwUIEf4iVNkFrW1EQK20DAZWgglgomkJik0IUsbDz78i2zsEFHkswszJ7ezDNFfcde7e7722lEvly7mcH6SLV2JZFO8h3kXJwIHsGHaeP1GKi+xPQuPgUdJyeEpxBDgn0C9lQokcE+omsqdBZ5IREV5XoKYG+ISsqtIqckeiyEu0S6GsKdIS0VGgNuUyB9gl0iCyq0Ay5JtGmEr0j0IESrZPoE9JQoY3igdPQhxRoPhqZCm0izynQIYHm02pBhbaQFxLVVBAFOiLQnhJtk6i2SsTD7gn0XfZNDcwO84VsmA2+FPA3y3F2Kl3J5q+HD0pdPAweslzWY+CPBK7blQyeubK3xH/g+aaiKQI8nK215HhIoaepLj38nMB1pa3BQ4r5dipc1zMZfM7xXaIcZ1vUD6fqiz38mMB1TbnBQ44h1mPgBwSuO3jxXoA5asrxzVT4jRwu8N0/0FunLiI8vDMBjXuWafDt0lGDbxVL7Ly9/wuaeF/be+9JDwAAAABJRU5ErkJggg=='/>"
            >
                <slide
                    v-for="(cocktail, i) in cocktails"
                    :key="cocktail.id"
                    :index="i"
                    class="carousel-slide mt-5"
                >
                    <div @click="goToCocktailPage(cocktail)">
                        <img height="500px" :src="getImage(cocktail)">
                        <span class="carousel-slide-title mx-auto">{{ cocktail.name }}</span>
                        <div class="carousel-slide-subtitle mx-auto">{{ cocktail.description }}</div>
                    </div>
                </slide>
            </carousel-3d>
            <cocktail-footer />
        </div>
    </div>
</template>
<script>
    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import cocktailService from '@/services/cocktails-service'
    import config from '@/mixins/config'
    import CocktailFooter from './CocktailFooter.vue'

    export default {
        components: {
            Carousel3d,
            Slide,
            CocktailFooter
        },
        mixins: [config],
        data() {
            return {
                cocktails: [{}, {}, {}],
                slides: 1000
            }
        },
        created() {
            this.fetchCocktails()
        },
        methods: {
            async fetchCocktails() {
                const filterObject = {
                    cocktailBase: 'WINE'
                }
                this.cocktails = (await cocktailService.getAll({ filter: filterObject })).content
                this.slides = this.cocktails.length
            },
            getImage(cocktail) {
                if (cocktail.image) {
                    return this.config.images + cocktail.image.relativePath + '/' + cocktail.image.fileName
                } else {
                    return require('@/assets/cocktails/white-sangria.png')
                }
            },
            goToCocktailPage(cocktail) {
                this.$router.push('/cocktails/' + cocktail.id)
            },
            goToHomeRoute() {
                this.$router.push('/home')
            }
        }
    }
</script>

<style>
    .carousel-3d-controls {
        margin-top: 250px !important;
        left: 10% !important;
        width: 80% !important;
    }

    .prev {
        color: white !important;
    }

    .next{
        color: white !important;
    }

    .left-1 .carousel-slide-title {
        font-size: 52px !important;
        line-height: 60px !important;
    }

    .left-1 .carousel-slide-subtitle {
        opacity: 0 !important;
    }

    .right-1 .carousel-slide-title {
        font-size: 52px !important;
        line-height: 60px !important;
    }

    .right-1 .carousel-slide-subtitle {
        opacity: 0 !important;
    }

</style>

<style scoped>

    .carousel-slide-title {
        font-size: 74px;
        line-height: 84px;
        color: #fffdf6;
        font-weight: 400;
        font-family: "Source Serif Pro";
    }
    .carousel-slide-subtitle {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        color: #e6e1d7;
        font-weight: 300;
        font-family: "C FAsty Std";
    }
    .carousel-3d-controls {
        position: absolute;
        top: 50%;
        height: 0;
        margin-top: 130px !important;
        left: 0;
        width: 200px;
        z-index: 1000;
    }
    .carousel-container{
        margin-top: 150px;
        display: flex;
        flex-direction: column;
        height: 1000px;
        width: 100%;
    }

    .carousel-slide {
        background: transparent;
        box-shadow: 0;
        border-style: none;
        text-align: center;
    }

    .left-1{
        opacity: 0.5 !important;
    }

    .right-1{
        opacity: 0.5 !important;
    }
    .cocktails-title{
        margin-top: 50px;
    }
    .cocktails-header {
        display: flex;
        align-items:center;
        padding-top: 20px;
        margin: 0px 0 0 20px;
    }
    .cocktails-type {
        font-size: 130px;
        letter-spacing: -2px;
        line-height: 57px;
        color: #ecab46;
        font-weight: 700;
        font-family: "Playfair Display";
        text-align: center;
    }

    .cocktails-type-subtitle {
        margin-top: 50px;
        margin-left: 170px;
        font-size: 80px;
        letter-spacing: -2px;
        line-height: 33px;
        color: #e7e5df;
        font-weight: 300;
        font-family: "C FAsty Std";
        text-align: center;
    }

    .secondary-text-box {
        margin-top: 70px;
        display: flex;
        justify-content:center;
    }
    .secondary-text {
        width: 755px;
        height: 164px;
        font-size: 80px;
        letter-spacing: -1px;
        line-height: 72px;
        color: #fffdf6;
        font-weight: bold;
        font-family: "C FAsty Std";
        text-align: center;
    }
    .home-text {
        font-size: 23px;
        letter-spacing: 0px;
        color: #fffdf6;
        font-weight: bold;
        margin-left: 10px;
    }
    .cocktail-background {
        background: url('~@/assets/cocktails/background_cocktails.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
    }
</style>
