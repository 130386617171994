import restAPI from '@/utils/rest-api'

class WineService {
    getColors() {
        return restAPI.get(encodeURI('/wine-colors?sort=["pos","ASC"]'))
    }

    getTypes() {
        return restAPI.get(encodeURI('/wine-types?sort=["pos","ASC"]'))
    }

    getDryness() {
        return restAPI.get(encodeURI('/wine-dryness?sort=["pos","ASC"]'))
    }

    getBottleVolumes() {
        return restAPI.get('/wine-bottle-volumes')
    }

    getSpecialTypes() {
        return restAPI.get(encodeURI('/wine-special-types?sort=["pos","ASC"]'))
    }

    getOrigins() {
        return restAPI.get(encodeURI('/wine-origins?sort=["pos","ASC"]'))
    }

    getVarieties(requestData) {
        requestData = requestData || {}
        const filter = requestData.filter || {}
        const sort = requestData.sort || []
        const range = (requestData.range) ? JSON.stringify(requestData.range) : ''

        return restAPI.get(encodeURI(`/wine-varieties?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&range=${range}`))
    }

    getAll(requestData, cancelToken) {
        const filter = requestData.filter || {}
        const sort = requestData.sort || []
        const range = (requestData.range) ? JSON.stringify(requestData.range) : ''
        let url = `/wines?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&range=${range}`
        if (requestData.applyClustering !== undefined) {
            url += `&applyClustering=${requestData.applyClustering}`
        }
        return restAPI.get(encodeURI(url), cancelToken)
    }

    get(id) {
        return restAPI.get(`/wines/${id}`)
    }

    scan(barCode) {
        return restAPI.post(`/wines/scan/${barCode}`)
    }
}
export default new WineService()
