<template>
    <div class="carousel-card">
        <img
            :src="require('@/assets/wine-roads/carousel-pin.png')"
            class="carousel-pin"
        >
        <h3 class="region-name">{{ region.name }}</h3>
        <span class="wine-fields">
            {{ region.children.length !== 0 ? region.children.length : 1 }} {{ region.children.length > 1 ? $t('WineRoadsRoute.wineFields') : $t('WineRoadsRoute.wineField') }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            region: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
.carousel-card {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.carousel-pin {
    width: 60px;
    height: 71px;
}
.region-name {
    margin-top: 1rem;
    font-size: inherit;
    letter-spacing: -1px;
    line-height: 22px;
    color: #edede3;
    font-weight: bold;
    text-align: center;
}
.wine-fields {
    margin-top: 0.5rem;
    font-size: 18px;
    line-height: 17px;
    color: #edede3;
    font-weight: 300;
    text-align: center;
}
</style>
