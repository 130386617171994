<template>
    <div class="page-container">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <h1 v-if="wineRegion" class="title">{{ $t('WineRoadsStep3AllWinesRoute.winesOf') }} {{ wineRegion.nameGenitive || 'της περιοχής' }}</h1>
        <div class="total-wines">
            - {{ wines.length }} {{ wines.length !== 1 ? $t('WineRoadsStep3AllWinesRoute.labels') : $t('WineRoadsStep3AllWinesRoute.label') }} -
        </div>
        <wine-carousel
            :wines="wines"
            :bucket-size="9"
            carousel-height="90rem"
            card-height="450px"
            route-prefix="/wine-roads"
            class="carousel"
        />
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/sommelier/wine-food-combinations" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.abSommelier')" :text="$t('WineRoadsRoute.combineFoodWithWine')" theme="red" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.wineMastery')" :text="$t('WineRoadsRoute.chοoseWine')" theme="yellow" />
                </div>
            </router-link>
        </div>
        <enjoy-responsibly :bottom="'20rem'" />
        <disclaimer />
    </div>
</template>
<script>
    import wineRegionService from '@/services/wine-region-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import WineCarousel from '@/components/WineCarousel'
    import FooterTile from '@/components/FooterTile'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, WineCarousel, FooterTile, Disclaimer, EnjoyResponsibly },
        data() {
            return {
                wines: [],
                wineRegion: null,
                path: [],
                rootPath: [{ name: 'Δρόμοι του Κρασιού', path: '/wine-roads' }]
            }
        },
        async created() {
            const wineRegion = await wineRegionService.getWineRegionById(this.$route.params.regionId)
            this.wineRegion = wineRegion

            const path = await wineRegionService.getBreadcrumbPath(this.rootPath, this.wineRegion)
            this.path = [...path, { name: 'Όλα τα κρασιά' }]
            this.fetchAllWines()
        },
        methods: {
            fetchAllWines() {
                wineRegionService.getWinesByRegionId(this.$route.params.regionId).then(wines => {
                    this.wines = wines.content
                })
            }
        }
    }
</script>
<style scoped>
.page-container {
    background: url('~@/assets/wine-roads/all-wines-background.png');
}
.title {
    margin-top: 1.2rem;
    text-align: center;
    font-size: 3.5rem;
    color: #882c2e;
    font-family: 'CFABold';
}
.total-wines {
    text-align: center;
    font-family: 'CFALight';
    font-size: 1.2rem;
}
.carousel {
    margin-top: 2rem;
}
.wine-roads-container {
    display: flex;
    height: 10vh;
}
.footer-tile {
    height: 100%;
    width: 100%;
}

</style>
