export default function randomizeItems(items) {
    if (items.length === 0) {
        return items
    }

    const shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]]
        }
        return a
    }
    const maxRank = 10
    const rankGroups = []

    for (let i = 0; i <= maxRank; i++) {
        rankGroups[i] = []
    }

    items.forEach(item => {
        rankGroups[item.rank].push(item)
    })

    items = []
    for (let i = 0; i <= maxRank; i++) {
        rankGroups[i] = shuffle(rankGroups[i])
    }

    for (let i = 0; i <= maxRank; i++) {
        rankGroups[i].forEach(item => {
            items.push(item)
        })
    }
    return [...items]
}
