<template>
    <div ref="breadCrumbBar" class="breadcrumb-bar">
        <div class="home-button" @click="goHome">
            <slot />
        </div>
        <div :class="['breadcrumb', {'dark': isDark}]" :style="`--font-size:${fontSize}px`">
            <span v-for="(item, index) of path" :key="item.id" class="routes">
                <router-link
                    v-if="index !== path.length - 1 && item.path"
                    :to="item.path"
                >
                    <img v-if="!isDark" :src="require('@/assets/common/breadcrumb-arrow-red.png')">
                    <img v-else :src="require('@/assets/common/breadcrumb-arrow-white.png')">
                    {{ item.name }}
                </router-link>
                <span v-else :class="['last-step', {'dark': isDark}]">
                    <img v-if="!isDark" :src="require('@/assets/common/breadcrumb-arrow-red.png')">
                    <img v-else :src="require('@/assets/common/breadcrumb-arrow-white.png')">
                    {{ item.name }}
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        props: {
            path: {
                type: Array,
                required: true
            },
            isDark: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            fontSize() {
                const parentContainerWidth = (this.$refs.breadCrumbBar) ? this.$refs.breadCrumbBar.clientWidth : document.body.clientWidth
                let pathItemsTextLength = 0
                this.path.forEach(item => {
                    pathItemsTextLength += item.name.length
                })

                return Math.min(23, (parentContainerWidth / pathItemsTextLength) * 1.25)
            }
        },
        methods: {
            goHome() {
                this.$router.push({ path: '/home' })
            }
        }
    }
</script>

<style scoped>
.breadcrumb-bar {
    display: flex;
    align-items: center;
    margin: 0px 0 0 20px;
    padding-top: 20px;
}

.home-button {
    cursor: pointer;
    filter: drop-shadow(0px 5px 5px rgba(78,76,73,0.5));
}

.breadcrumb {
    margin: auto 0;
    background-color: transparent;
    --font-size:  20px;
}

.breadcrumb a {
    font-size: var(--font-size);
    color: #d1a679;
    font-family: CFALight;
    font-weight: 600;
    text-decoration: none;
}
.routes img {
    margin-left: 0.5rem;
}

.last-step  {
    color: #ac3846;
    font-size: var(--font-size);
    font-family: CFABold;
}

.dark {
    color: #fff;
}

</style>
