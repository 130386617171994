<template>
    <div class="food-combination-container">
        <div class="main-section text-center">
            <div class="head-section">
                <breadcrumb :path="path" :is-dark="true">
                    <img :src="require('@/assets/common/home-button.png')">
                </breadcrumb>
                <h1>
                    {{ foodCombination | nullCheck('name') }}
                </h1>
                <div class="select-item">
                    -{{ $t('WineFoodCombinationStep1Route.selectItem') }}-
                </div>
                <div v-if="foodCombination" class="food-combination-container">
                    <router-link
                        v-for="foodCombinationChild in foodCombination.children"
                        :key="foodCombinationChild.id"
                        v-slot="{ navigate }"
                        custom
                        :to="{ path: `/sommelier/wine-food-combinations/2/${foodCombinationChild.id}` }"
                    >
                        <div
                            role="link"
                            class="food-item"
                            @click="navigate"
                            @keypress.enter="navigate"
                        >
                            <img v-if="foodCombinationChild.platePhoto" :src="config.host + '/' + foodCombinationChild.platePhoto.relativePath + '/' + foodCombinationChild.platePhoto.fileName" class="food-image">
                            <img v-else :src="require('@/assets/sommelier/chicken_food.png')">
                            <div class="mt-3">
                                {{ foodCombinationChild.name }}
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="wine-pairing-section">
                <img :src="require('@/assets/sommelier/sommelier.png')" class="sommelier">
                <h1 class="mt-4">
                    {{ $t('WineFoodCombinationStep1Route.goesWith') }}
                </h1>
                <div class="labels">- {{ foodWinesTotal }} {{ $t('WineFoodCombinationStep1Route.labels') }} -</div>
                <wine-carousel
                    v-if="foodWines.length > 0"
                    :wines="foodWines"
                    route-prefix="/sommelier/wine-food-combinations"
                    class="wine-carousel"
                />
                <div v-else class="no-wines-text">
                    {{ $t('WineFoodCombinationStep1Route.noWines') }}
                </div>
                <router-link :to="`/sommelier/wine-food-combinations/1/${$route.params.foodId}/all-wines`">
                    <div class="all-wines-btn">
                        <span>
                            {{ $t('WineFoodCombinationStep1Route.allWines') }}
                        </span>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="sidebar">
            <food-combination-side-bar
                :active-food-combination-id="parseInt($route.params.foodId)"
                @select="viewParentFoodCombination"
            />
        </div>
        <enjoy-responsibly :right="'9rem'" />
        <disclaimer />
    </div>
</template>
<script>
    import FoodCombinationSideBar from '@/components/sommelier/FoodCombinationSideBar'
    import WineCarousel from '@/components/WineCarousel.vue'
    import foodComboService from '@/services/food-combo-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import randomizeItems from '@/utils/randomize-items'
    import config from '@/mixins/config'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            Breadcrumb,
            FoodCombinationSideBar,
            WineCarousel,
            Disclaimer,
            EnjoyResponsibly
        },
        mixins: [config],
        beforeRouteUpdate(to, from, next) {
            this.init(to.params.foodId)
            next()
        },
        data() {
            return {
                foodWines: [],
                foodWinesTotal: null,
                foodCombination: null,
                rootPath: [{ name: 'AB Sommelier', path: '/sommelier' }, { name: 'Αρμονία Κρασιού & Φαγητού', path: '/sommelier/wine-food-combinations' }],
                path: []
            }
        },
        created() {
            this.init(this.$route.params.foodId)
        },
        methods: {
            init(foodId) {
                this.fetchFoodCombination(foodId)
                this.fetchFoodWines(foodId)
            },
            async fetchFoodCombination(foodId) {
                const foodCombination = await foodComboService.getFoodCombinationById(foodId).then((res) => {
                    this.foodCombination = res
                    return res
                })
                foodComboService.getBreadcrumbPath(this.rootPath, foodId, foodCombination).then((resp) => {
                    this.path = resp
                })
            },
            viewParentFoodCombination(foodId) {
                this.$router.push({
                    path: `/sommelier/wine-food-combinations/1/${foodId}`
                })
            },
            fetchFoodWines(foodId) {
                foodComboService.getWinesByFoodId({ foodId, range: [0, 50] })
                    .then(res => {
                        this.foodWinesTotal = res.totalElements
                        this.foodWines = randomizeItems(res.content)
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.food-combination-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
}
.main-section {
    color: #edede3;
    font-family: 'CFALight';
    display: flex;
    flex-direction: column;
    flex: 1;
}
.main-section h1 {
    font-family: 'CFABold';
    font-size: 4.5rem;
}
.main-section  span {
    font-size: 1.25rem;
}

.food-item {
    font-size: 1.3rem;
    font-family: 'CFABold';
    margin: .5rem 2rem 0rem 2rem;
    flex: 0 40%;
    align-self: center;
}
.food-item img {
    width: 15.4rem;
    height: auto;
}
.head-section {
    background: url('~@/assets/sommelier/foodcombination_head_background.png');
    padding-bottom: 21rem;
    height: 60%;
}
.select-item {
    padding-bottom: 1.5rem;
}
.sommelier {
    margin-top: -6rem;
}

.no-wines-text{
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 2rem;
    font-size: 2rem;
    font-family: 'CFABold';
}

.wine-pairing-section {
    background: url('~@/assets/sommelier/foodcombination_main_background.png');
    height: 40%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #7f2f2d;
    font-family: 'CFALight';
}

.wine-pairing-section h1 {
    font-size: 2.5rem;
    font-family: 'CFABold';
    margin-bottom: 0;
}

.labels {
    font-size: 1rem;
    padding-bottom: 1rem;
}

.sidebar {
    width: 13rem;
    height: 100%;
}

.all-wines-btn {
    max-width: max-content;
    margin: 0.1rem auto 0;
    background-color: #882c2e;
    padding: 1.2rem 4.8rem;
    border-radius: 12px;
    filter: drop-shadow(3.625px 1.69px 3.5px rgba(27,27,27,0.34));
    cursor: pointer;
}

.all-wines-btn span {
    color: #fff;
    font-size: 1.5rem;
    font-family: 'CFABold';
}

</style>
