import restAPI from '@/utils/rest-api'

export default {
    getFoodCombinationById(id) {
        return restAPI.get(encodeURI(`/wine-food-combinations/${id}`))
    },
    getWinesByFoodId(requestData, cancelToken) {
        const range = (requestData.range) ? JSON.stringify(requestData.range) : ''

        return restAPI.get(encodeURI(`/wine-food-combinations/${requestData.foodId}/wines?range=${range}`), cancelToken)
    },
    getRootFoodCombinations() {
        return restAPI.get(encodeURI('/wine-food-combinations/roots'))
    },
    getBreadcrumbPath(rootPath, foodId, currentFoodCombo) {
        let path = []
        if (!foodId) {
            path = [...rootPath]
            return Promise.resolve(path)
        }
        while (currentFoodCombo) {
            currentFoodCombo.path = `/sommelier/wine-food-combinations/${currentFoodCombo.step}/${currentFoodCombo.id}`
            path.unshift(currentFoodCombo)
            currentFoodCombo = currentFoodCombo.parent
        }
        path = [...rootPath, ...path]
        return Promise.resolve(path)
    }
}
