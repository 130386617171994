<template>
    <div>
        <div class="text-center">
            <img :src="require('@/assets/wine-roads/carousel-pin.png')">
            <h1>{{ wineRegion.name }}</h1>
            <div class="description">
                {{ wineRegion.shortDescription }}
            </div>
            <div class="long-description mt-5 pb-5">
                {{ wineRegion.longDescription }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            wineRegion: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style scoped>
h1 {
    font-size: 4rem;
    color: #d1a679;
    font-family: 'CFABold';
    padding: 1.3rem 0;
}
.description {
    font-size: 2rem;
    color: #d1a679;
    font-family: 'CFALight';
    padding: 0 4rem;
    min-height: 8.5rem;
}
.long-description {
    min-height: 21rem;
    columns: 2;
    width: 90%;
    margin: 0 auto;
    text-align: left;
    color: #fff;
    font-family: 'CFALight';
    font-size: 1.063rem;
}
</style>
