<template>
    <div v-if="wine" class="pr-3 container">
        <div class="mb-5">
            <div class="supplier-text">- {{ wine.winery }} -</div>
            <div class="name-text">{{ wine.commercialName }}</div>
            <div class="volume-text">{{ wine.wineDryness.name || '' }} {{ wine.wineColor ? wine.wineColor.name : '' }} {{ wine.wineBottleVolume.volume || '' }}</div>
            <seasonality :seasons="wine.seasons" class="mt-3" />
        </div>
        <div class="wine-bordered-details-container">
            <div class="details-text-container">
                <div class="details-wrapper color">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/red_wine.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.color') }}</div>
                        <div class="details-common-text">{{ wine.wineColor ? wine.wineColor.name : '-' }}</div>
                    </div>
                </div>
                <div class="details-wrapper type">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.type') }}</div>
                        <div class="details-common-text"> {{ wine.wineType.name || '-' }} </div>
                    </div>
                </div>
                <div class="details-wrapper wine-dryness">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/color_wine.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.wineDryness') }}</div>
                        <div class="details-common-text"> {{ wine.wineDryness.name || '-' }} </div>
                    </div>
                </div>
                <div class="details-wrapper bottle-volume">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.bottleVolume') }}</div>
                        <div class="details-common-text"> {{ wine.wineBottleVolume.volume || '-' }}</div>
                    </div>
                </div>
                <div class="details-wrapper country">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.country') }}</div>
                        <div class="details-common-text"> {{ wine.country || '-' }}</div>
                    </div>
                </div>
                <div v-if="wine.wineOrigin" class="details-wrapper origin">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.wineOrigin') }}</div>
                        <div class="details-common-text"> {{ wine.wineOrigin.name || '-' }}</div>
                    </div>
                </div>
                <div class="details-wrapper location">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.location') }}</div>
                        <div class="details-common-text"> {{ wine.location || '-' }}</div>
                    </div>
                </div>
                <div class="details-wrapper variety">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/grape.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.variety') }}</div>
                        <div class="details-common-text">
                            {{ wine.varieties.length === 0 ? '-' : '' }}
                            <div
                                v-for="variety in wine.varieties"
                                :key="variety.name"
                            >
                                {{ variety.name || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-wrapper single-variety">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/grape.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.singleVariety') }}</div>
                        <div class="details-common-text"> {{ wine.singleVariety ===true ? 'Nαι' : 'Όχι' }}</div>
                    </div>
                </div>
                <div class="details-wrapper temperature">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.temperature') }}</div>
                        <div class="details-common-text">
                            {{ wine.minServingTemperature === wine.maxServingTemperature ? wine.minServingTemperature : wine.minServingTemperature + '°C - ' + wine.maxServingTemperature }}°C
                        </div>
                    </div>
                </div>
                <div class="details-wrapper aging-info">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/wine_smell.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.agingInfo') }}</div>
                        <div class="details-common-text"> {{ wine.agingInfo || '-' }}</div>
                    </div>
                </div>
                <!-- <div class="details-wrapper extra-spacial-info">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.extraSpecialInfo') }}</div>
                        <div class="details-common-text"> {{ wine.extraSpecialInfo || '-' }}</div>
                    </div>
                </div> -->
                <div class="details-wrapper food-combinations">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/wine_test.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.foodCombinations') }}</div>
                        <div class="details-common-text">
                            <div
                                v-for="combination in wine.foodCombinations"
                                :key="combination.name"
                            >
                                {{ combination.name || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-wrapper smell">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/wine_smell.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.smell') }}</div>
                        <div class="details-common-text"> {{ wine.smellInfo || '-' }} </div>
                    </div>
                </div>
                <div class="details-wrapper taste">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/wine_test.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('WineDetailsTable.taste') }}</div>
                        <div class="details-common-text">{{ wine.tasteInfo || '-' }}</div>
                    </div>
                </div>
                <div class="details-wrapper disclaimer">
                    <div class="fix-width">
                        <div class="disclaimer-header-text">{{ $t('WineDetailsTable.disclaimer') }}</div>
                        <div class="disclaimer-common-text">{{ $t('WineDetailsTable.disclaimerInfo') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Seasonality from '@/components/Seasonality'

    export default {
        components: {
            Seasonality
        },
        props: {
            wine: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

.container {
    width: 100%;
}

.color{
    grid-area: color;
}
.type{
    grid-area: type;
}
.wine-dryness{
    grid-area: wine-dryness;
}
.bottle-volume{
    grid-area: bottle-volume;
}
.country{
    grid-area: country;
}
.origin{
    grid-area: origin;
}
.location{
    grid-area: location;
}
.variety{
    grid-area: variety;
}
.single-variety{
    grid-area: single-variety;
}
.temperature{
    grid-area: temperature;
}
.aging-info{
    grid-area: aging-info;
}
.extra-spacial-info{
    grid-area: extra-spacial-info;
}
.food-combinations{
    grid-area: food-combinations;
}
.smell{
    grid-area: smell;
}
.taste{
    grid-area: taste;
}
.disclaimer{
    grid-area: desclaimer;
}
.details-text-container{
    width: 100%;
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: auto;
    grid-template-areas:
        "color type"
        "wine-dryness bottle-volume"
        "country origin"
        "location variety"
        "single-variety temperature"
        "food-combinations aging-info"
        "extra-spacial-info extra-spacial-info"
        "smell smell"
        "taste taste"
        "desclaimer desclaimer";
    justify-content: center;
    column-gap: 1.5vw;
    row-gap: 1.5vh;
    padding: 2%;
    overflow-y: scroll;
    height: 430px;
}

.wine-bordered-details-container{
    height: 100%;
    width: 100%;
    padding: .5rem;
    border: 1px solid #dfdfdc;
    color: black;
}

.details-wrapper{
    display: flex;
    flex-direction: row;
    justify-self: start;
}

.img-container{
    display: flex;
    min-width: 52px;
    height: 51px;
    justify-content: center;
}
.img-container img {
    height: 100%;
}

.fix-width {
    display: flex;
    flex-direction: column;
    padding-left: .7rem;
}

.details-header-text{
    color: #ac3846;
    font-size: clamp(15px,1.6vw,18px);
    font-family: CFALight;
    font-weight: 700;
}

.disclaimer-header-text{
    color: #ac3846;
    font-size: clamp(10px,1.6vw,12px);
    font-family: CFALight;
    font-weight: 700;
}

.details-common-text{
    color: #59595b;
    font-size: clamp(15px,1.6vw,18px);
    font-family: CFALight;
    font-weight: 700;
}

.disclaimer-common-text{
    color: #59595b;
    font-size: clamp(8px,1.6vw,10px);
    font-family: CFALight;
    font-weight: 700;
}

.supplier-text{
    color: #ac7f5e;
    font-size: clamp(15px,3vw,25px);
    font-family: CFABold;
}

.name-text{
    color: #ac3846;
    font-size: clamp(30px,5.7vw,45px);
    font-family: CFABold;
    line-height: normal;
}

.volume-text{
    color: #808181;
    font-size: clamp(15px,3vw,20px);
    font-family: CFABold;
}

</style>
