import restAPI from '@/utils/rest-api'

export default {
    async getBreadcrumbPath(rootPath, currentSecretId) {
        let path = []
        if (!currentSecretId) {
            path = [...rootPath]
            return Promise.resolve(path)
        }
        let currentSecret = await this.getSecretById(currentSecretId)

        while (currentSecret) {
            currentSecret.path = `/sommelier/wine-secrets/${currentSecret.id}`
            path.unshift(currentSecret)
            currentSecret = currentSecret.parent
        }
        path = [...rootPath, ...path]
        return Promise.resolve(path)
    },
    getSecretById(id) {
        return restAPI.get(encodeURI(`/wine-secrets/${id}`))
    },
    getRootSecrets() {
        return restAPI.get(encodeURI('/wine-secrets/roots'))
    }
}
