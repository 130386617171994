<template>
    <div class="carousel-container" :style="{height: carouselHeight}">
        <spinner v-if="wines.length<1" class="spinner" />
        <vueper-slides
            v-if="wines.length>0"
            slide-multiple
            :bullets="false"
            :infinite="false"
            :touchable="false"
            :fixed-height="carouselHeight"
            class="no-shadow cont"
        >
            <vueper-slide
                v-for="(bucket, index) in wineBuckets"
                :key="index"
            >
                <template #content>
                    <div
                        class="wines-container"
                    >
                        <wine-carousel-card
                            v-for="wine in bucket"
                            :key="wine.id"
                            :wine="wine"
                            :card-height="cardHeight"
                            @click.native="goToWine(wine)"
                        />
                    </div>
                </template>
            </vueper-slide>
            <template #arrow-left>
                <img :src="require('@/assets/common/previous_arrow_image.png')" alt="previous button" class="arrow">
            </template>
            <template #arrow-right>
                <img :src="require('@/assets/common/next_arrow_image.png')" alt="next button" class="arrow">
            </template>
        </vueper-slides>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import WineCarouselCard from '@/components/WineCarouselCard'
    import Spinner from '@/components/Spinner'

    export default {
        components: {
            VueperSlides,
            VueperSlide,
            WineCarouselCard,
            Spinner
        },
        props: {
            wines: {
                type: Array,
                required: true
            },
            bucketSize: {
                type: Number,
                required: false,
                default: 3
            },
            carouselHeight: {
                type: String,
                required: false,
                default: '27rem'
            },
            cardHeight: {
                type: String,
                required: false,
                default: '360px'
            },
            routePrefix: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                wineBuckets: []
            }
        },
        watch: {
            wines: {
                immediate: true,
                handler(newVal) {
                    this.preprocessWines()
                }
            }
        },
        created() {
            this.preprocessWines()
        },
        methods: {
            goToWine(wine) {
                this.$router.push({ path: `${this.routePrefix}/wines/${wine.id}` })
            },
            preprocessWines() {
                let index = 1
                let bucket = []
                this.wineBuckets = []
                for (const wine of this.wines) {
                    bucket.push(wine)
                    if (index % this.bucketSize === 0) {
                        this.wineBuckets.push(bucket)
                        bucket = []
                    }
                    index++
                }
                if (bucket.length > 0) {
                    this.wineBuckets.push(bucket)
                }
            }
        }
    }
</script>

<style lagn="scss" scoped>
.carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.spinner {
    align-self: center;
    margin-top: 8rem;
}
.wines-container {
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.cont {
    width: 100%;
}

.arrow {
    width: 80%;
}

</style>
