import { render, staticRenderFns } from "./SpiritCarouselCard.vue?vue&type=template&id=2285f1de&scoped=true&"
import script from "./SpiritCarouselCard.vue?vue&type=script&lang=js&"
export * from "./SpiritCarouselCard.vue?vue&type=script&lang=js&"
import style0 from "./SpiritCarouselCard.vue?vue&type=style&index=0&id=2285f1de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2285f1de",
  null
  
)

export default component.exports