<template>
    <div class="gallery-container">
        <div v-if="images" class="card-carousel">
            <div v-if="images.length>1" class="thumbnails">
                <div
                    v-for="(image, index) in images"
                    :key="image.id"
                    :class="['thumbnail-image', (activeImage == index) ? 'active' : '']"
                    @click="activateImage(index)"
                >
                    <img :src="getThumbnailUrl(image)">
                </div>
            </div>
            <div class="card-img">
                <img v-if="images.length>0" :src="currentImage" alt="">
                <img v-else :src="require(`@/assets/common/default_${bottleType}.png`)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            images: {
                type: Array,
                required: true
            },
            bottleType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                activeImage: 0
            }
        },
        computed: {
            currentImage() {
                return `${this.config.host}/${this.images[this.activeImage].relativePath}/${this.images[this.activeImage].fileName}`
            }
        },
        methods: {
            getThumbnailUrl(image) {
                return `${this.config.host}/${image.relativePath}/${image.fileName}`
            },
            activateImage(imageIndex) {
                this.activeImage = imageIndex
            }
        }
    }
</script>

<style scoped>
.gallery-container{
    padding: 2rem 2rem 2rem 4rem;
    width: 50%;
}
.card-carousel {
    display: flex;
    justify-content: space-between;
    user-select: none;
    position: relative;
}

.thumbnails {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    width: 33px;
}
.thumbnail-image + .thumbnail-image {
    margin-top: 2rem;
}
.thumbnail-image > img {
    width: 100%;
    height: auto;
    opacity: 0.6;
    transition: all 250ms;
}
.thumbnail-image:hover > img,
.thumbnail-image.active > img {
    opacity: 1;
}
.card-img {
    position: relative;
    margin-bottom: 15px;
    height: 620px;
}
.card-img > img {
    width: auto;
    height: 100%;
    display: block;
    margin: 0 auto;
    filter: drop-shadow(9.119px 6.151px 6.5px rgba(27,27,27,0.5));
}

</style>
