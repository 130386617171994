<template>
    <div class="wine-secrets">
        <div class="header-background">
            <breadcrumb :path="path" :is-dark="true">
                <img :src="require('@/assets/common/home-button.png')">
            </breadcrumb>
            <h2 class="page-title">{{ $t('WineSecretsRoute.pageTitle') }}</h2>
            <img :src="require('@/assets/common/opening_quote.png')" class="quote-symbol">
            <span class="quote-text">{{ $t('WineSecretsRoute.quoteText[0]') }}<br>{{ $t('WineSecretsRoute.quoteText[1]') }}</span>
            <img :src="require('@/assets/common/closing_quote.png')" class="quote-symbol">
        </div>
        <div class="detail-tiles-container">
            <h1 class="detail-title">
                {{ $t('WineSecretsRoute.detailsContainerTitle') }}
            </h1>
            <div class="detail-description">
                {{ $t('WineSecretsRoute.detailsContainerDescription[0]') }}<span>{{ $t('WineSecretsRoute.detailsContainerDescription[1]') }}</span><br>{{ $t('WineSecretsRoute.detailsContainerDescription[2]') }}
            </div>
            <div v-if="wineSecrets" class="tile-container">
                <div
                    v-for="secret in wineSecrets"
                    :key="secret.id"
                    class="tile"
                    @click="navigateToWineSecrets(secret.id)"
                >
                    <div class="tile-inner">
                        <img v-if="secret" class="tile-icon" :src="config.host + '/' + secret.buttonImage.relativePath + '/' + secret.buttonImage.fileName">
                        <div class="tile-title">{{ secret.name }}</div>
                    </div>
                </div>
            </div>
            <img :src="require('@/assets/sommelier/wine_secrets_decoration_wing.png')" alt="">
        </div>
        <enjoy-responsibly />
    </div>
</template>
<script>
    import Breadcrumb from '@/components/Breadcrumb'
    import config from '@/mixins/config'
    import wineSecretsService from '@/services/wine-secrets-service'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, EnjoyResponsibly },
        mixins: [config],
        data() {
            return {
                path: [
                    { name: 'AB Sommelier', path: '/sommelier' },
                    { name: 'Οινικά Μυστικά', path: '/sommelier/wine-secrets' }
                ],
                wineSecrets: null
            }
        },
        created() {
            wineSecretsService.getRootSecrets().then(res => { this.wineSecrets = res.content })
        },
        methods: {
            navigateToWineSecrets(winesecretId) {
                this.$router.push({
                    path: `/sommelier/wine-secrets/${winesecretId}`
                })
            }
        }
    }
</script>
<style scoped>
.wine-secrets {
    height: 100%;
}
.header-background {
    background: url('~@/assets/sommelier/wine_secrets_top_background.png');
    width: 100%;
    height: 930px;
    position: relative;
}
.page-title {
    font-family: 'CFABold';
    font-size: 3.4rem;
    color: #e7e5df;
    position: absolute;
    top: 27%;
    left: 51%;
}

.quote-symbol {
    position: absolute;
    top: 40%;
    left: 48%;
    width: 33px;
    height: 22px;
}
.quote-symbol:last-child {
    top: 47.5%;
    left: 88.5%;
}
.quote-text {
    position: absolute;
    top: 40%;
    left: 48%;
    right: 5%;
    font-style: italic;
    font-size: 2rem;
    color: #edede3;
    text-align: center;
    line-height: 2.75rem;
    letter-spacing: 1px;
}
.detail-tiles-container {
    background: url('~@/assets/sommelier/wine_secrets_bottom_background.png');
    height: calc( 100vh - 930px);
    font-family: 'CFALight';
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.detail-title{
    margin: 3rem;
    color: #72725b;
    font-size: 3.75rem;
    font-family: 'CFABold';
}
.detail-description {
    color: #7f2f2d;
    font-size: 1.25rem;
}
.detail-description span {
    font-family: 'CFABold';
}
.tile-container {
    margin: 5rem 0;
    padding: 0 5rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.75rem;
    row-gap: 1rem;
    justify-content: center;
}
.tile {
    height: 220px;
    width: 220px;
    padding: 1.1rem;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 3.5px rgba(67,66,65,0.2));
    cursor: pointer;
}
.tile:first-child,
.tile:nth-child(6) {
    background-color: #7d2d2b;
}
.tile:nth-child(2),
.tile:nth-child(8) {
    background-color: #edede3;
}
.tile:nth-child(3),
.tile:nth-child(5) {
    background-color: #72725b;
}
.tile:nth-child(4),
.tile:nth-child(7) {
    background-color: #59595b;
}
.tile-inner {
    width: 100%;
    height: 100%;
    border: 3px solid #c39545;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tile-icon {
    margin-top: 1rem;
    height: 60px;
    width: auto;
}
.tile-title {
    margin-top: 1.3rem;
    font-family: 'CFABold';
    font-size: 1.2rem;
    color: #fff;
}
.tile:nth-child(5) .tile-title {
    width: 8ch;
}
.tile:nth-child(2) .tile-title,
.tile:nth-child(8) .tile-title {
    color: #59595b;
}
</style>
