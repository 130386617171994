<template>
    <div class="container">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <div class="sommelier">
            <img :src="require('@/assets/sommelier/sommelier_wine_secret.png')" width="150">
        </div>
        <vueper-slides
            v-if="wineSecrets"
            slide-multiple
            :bullets="false"
            :infinite="false"
            :touchable="false"
            :init-slide="initSlide()"
            fixed-height="1650px"
            class="no-shadow slides"
            @slide="changeIdOnUrl($event)"
        >
            <vueper-slide
                v-for="secret in wineSecrets"
                :key="secret.id"
            >
                <template #content>
                    <div class="secret-container">
                        <h1 class="title">{{ secret.name }}</h1>
                        <img class="tile-icon" :src="config.host + '/' + secret.innerImage.relativePath + '/' + secret.innerImage.fileName">
                        <div class="child-info">
                            <div
                                v-for="child in secret.children"
                                :key="child.id"
                            >
                                <div v-if="child.id == activeTab">
                                    <h3 class="child-title">{{ child.name }}</h3>
                                    <p class="child-description">{{ child.description }}</p>
                                </div>
                            </div>
                        </div>
                        <div v-if="secret.children.length>0" class="secret-tabs">
                            <div
                                v-for="child in secret.children"
                                :key="child.id"
                            >
                                <div
                                    :id="child.id"
                                    :class="['secret-tab', {'active-tab': activeTab == child.id}]"
                                    @click="selectTab($event)"
                                >
                                    {{ child.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </vueper-slide>
            <template #arrow-left>
                <img :src="require('@/assets/common/previous_gold_arrow.png')" alt="previous button" class="arrow">
            </template>
            <template #arrow-right>
                <img :src="require('@/assets/common/next_gold_arrow.png')" alt="next button" class="arrow">
            </template>
        </vueper-slides>
        <enjoy-responsibly />
    </div>
</template>
<script>
    import Breadcrumb from '@/components/Breadcrumb'
    import wineSecretsService from '@/services/wine-secrets-service'
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import config from '@/mixins/config'
    import 'vueperslides/dist/vueperslides.css'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, VueperSlides, VueperSlide, EnjoyResponsibly },
        mixins: [config],
        data() {
            return {
                wineSecrets: null,
                path: [],
                rootPath: [
                    { name: 'AB Sommelier', path: '/sommelier' },
                    { name: 'Οινικά Μυστικά', path: '/sommelier/wine-secrets' }
                ],
                activeTab: null
            }
        },
        watch: {
            $route: 'updateBreadcrumbPath'
        },
        async created() {
            this.updateBreadcrumbPath()
            const res = await wineSecretsService.getRootSecrets()
            this.wineSecrets = res.content
            this.activeTab = this.wineSecrets[this.$route.params.secretId - 1].children[0].id
        },
        methods: {
            selectTab(e) {
                this.activeTab = e.target.id
            },
            initSlide() {
                return +this.$route.params.secretId
            },
            changeIdOnUrl(params) {
                this.$router.push({
                    path: `/sommelier/wine-secrets/${params.currentSlide.index + 1}`
                })
                this.activeTab = this.wineSecrets[this.$route.params.secretId - 1].children[0].id
            },
            updateBreadcrumbPath() {
                wineSecretsService.getBreadcrumbPath(this.rootPath, this.$route.params.secretId).then(res => { this.path = res })
            }
        }
    }
</script>

<style scoped>
.container {
    background: url('~@/assets/sommelier/wine_secret_detail_route_background.png');
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
    overflow-y: hidden;
}
.slides {
   max-height: 100rem;
}

.sommelier {
    padding: 2.4rem 0 .5rem;
    display: flex;
    justify-content: center;
}
.secret-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.arrow {
    transform: translateY(-400px);
    width: 5rem;
}
.title {
    color: #7f2f2d;
    font-size: 4rem;
    font-family: "CFABold";
    padding: 2.5rem 0;
}
.child-info {
    width: 80%;
    text-align: center;
    margin: 4rem auto;
}
.child-title {
    color: #d1a24b;
    font-size: 3rem;
    font-family: "CFABold";
    padding: 1rem 0 1.5rem;
}
.child-description {
    color: #212121;
    font-size: 1.4rem;
    font-family: "CFALight";
    overflow-y: scroll;
    padding: 1rem 3rem;
    height: 30rem;
}
.secret-tabs {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .8rem;
}
.secret-tab {
    border: 2px solid #7f2f2d;
    color: #7f2f2d;
    font-family: 'CFABold';
    font-size: 1.4rem;
    height: 2.5rem;
    padding: 0 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}
.active-tab {
    background-color: #7f2f2d;
    color: #d1a24b;
}
.tile-icon{
    max-height: 25rem;
    max-width: 40rem;
}
</style>
