import axios from 'axios'
import randomizeItems from '@/utils/randomize-items'
import joinWines from '@/utils/join-wines'
import wineService from '@/services/wine-service'

export default {
    state: {
        wines: [],
        wine: {},
        cancelToken: {},
        varieties: [],
        page: 0
    },
    mutations: {
        setWines(state, wines) {
            state.wines = wines
        },
        setWine(state, wine) {
            state.wine = wine
        },
        setCancelToken(state, token) {
            state.cancelToken = token
        },
        setVarieties(state, varieties) {
            state.varieties = varieties
        },
        setWinesPage(state, page) {
            state.page = page
        }
    },
    actions: {
        fetchWines({ commit, state }, filters) {
            const pageSize = 30
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            const filterObject = {
                published: true
            }

            if (state.cancelToken.cancel) {
                state.cancelToken.cancel('')
            }

            filters.forEach(filter => {
                filterObject[filter.filterId] = filter.selectedValue
            })
            commit('setCancelToken', source)

            return wineService.getAll({ filter: filterObject, sort: ['rank', 'ASC'], range: [state.page, pageSize], applyClustering: true }, source.token).then(response => {
                const wines = (state.page === 0) ? randomizeItems(response.content) : joinWines(state.wines, randomizeItems(response.content))
                commit('setWinesPage', state.page + 1)
                commit('setWines', wines)
            })
        }
    },
    getters: {
        wines: (state) => {
            return state.wines
        },
        wine: (state) => {
            return state.wine
        },
        wineFilterVarieties: (state) => {
            return state.varieties
        }
    }
}
