<template>
    <div v-if="spirit" class="spirit-container">
        <img :src="spirit | avatarBuilder('spirit')" class="spirit-img">
        <div class="spirit-info ml-3">
            <span class="spirit-title">{{ spirit.commercialName | nullCheck }}</span>
            <span
                class="spirit"
            >- {{ spirit.distillingCompany }} -</span>
            <span class="spirit-color-text">{{
                spirit.category.name | nullCheck
            }}</span>
            <span v-if="spirit.bottleVolume">{{
                spirit.bottleVolume.volume
            }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            spirit: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
.spirit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 340px;
  cursor: pointer;
}

.spirit-img {
  max-height: 220px;
  height: 100%;
  bottom: 0;
  right: 0px;
  float: right;
  max-width: 100%;
  align-self: center;
}

.spirit-info {
  max-width: 250px;
  width: 23vw;
  display: flex;
  flex-direction: column;
}

.spirit-title {
  color: #ac3846;
  font-family: CFABold;
  font-size: clamp(15px, 2.4vw, 26px);
  line-height: 35px;
}

.spirit {
  color: #ac7f5e;
  font-family: CFALight;
  font-size: clamp(15px, 1.6vw, 18px);
}

.spirit-color-text {
  font-family: CFABold;
  color: #59595b;
  font-size: clamp(15px, 1.6vw, 18px);
  margin-top: auto;
}
</style>
