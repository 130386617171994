import { render, staticRenderFns } from "./WineDetailsTable.vue?vue&type=template&id=591a23dc&scoped=true&"
import script from "./WineDetailsTable.vue?vue&type=script&lang=js&"
export * from "./WineDetailsTable.vue?vue&type=script&lang=js&"
import style0 from "./WineDetailsTable.vue?vue&type=style&index=0&id=591a23dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591a23dc",
  null
  
)

export default component.exports