import restAPI from '@/utils/rest-api'

class Statisticservice {
    wineScanned(wineId) {
        return restAPI.post('/wine-page-stats', { action: 'wine_scanned', identifier: wineId })
    }

    wineVisited(wineId) {
        return restAPI.post('/wine-page-stats', { action: 'wine_visited', identifier: wineId })
    }

    spiritScanned(spiritId) {
        return restAPI.post('/spirit-page-stats', { action: 'spirit_scanned', identifier: spiritId })
    }

    spiritVisited(spiritId) {
        return restAPI.post('/spirit-page-stats', { action: 'spirit_visited', identifier: spiritId })
    }
}
export default new Statisticservice()
