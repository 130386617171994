<template>
    <div
        id="app"
        @mousedown="clickEvent()"
    >
        <key-board v-show="$store.getters.keyboardVisible" />
        <router-view />
        <vue-draggable-resizable
            v-if="tipExists"
            :resizable="false"
            @dragging="onDrag"
            @dragstop="onDragStop"
        >
            <tip-tooltip
                :dragging="dragging"
            />
        </vue-draggable-resizable>
        <transition name="grow">
            <tip-modal v-if="tipModalOpen" />
        </transition>
    </div>
</template>

<script>
    import idler from '@/utils/idler'
    import KeyBoard from '@/components/Keyboard'
    import TipModal from '@/components/TipModal'
    import TipTooltip from '@/components/TipTooltip'

    export default {
        components: {
            KeyBoard,
            TipModal,
            TipTooltip
        },
        data() {
            return {
                input: '',
                reloadAppTimer: null,
                dragging: false
            }
        },
        computed: {
            tipExists() {
                return this.$store.getters.tip(this.$route.meta.tipType)
            },
            tipModalOpen() {
                return this.$store.getters.tipModalOpen
            }
        },
        mounted() {
            this.resetReloadAppTimer()
            this.$store.dispatch('fetchRandomTips')
        },
        destroyed() {
            clearInterval(this.reloadAppTimer)
        },
        methods: {
            resetReloadAppTimer() {
                clearInterval(this.reloadAppTimer)

                this.reloadAppTimer = setInterval(() => {
                    fetch(window.location).then(() => {
                        location.reload()
                    }).catch(() => {})
                }, 10 * 60 * 1000)
            },
            clickEvent() {
                this.resetReloadAppTimer()
                idler.reset()
            },
            onDrag() {
                this.dragging = true
            },
            onDragStop() {
                this.dragging = false
            }
        }
    }
</script>

<style>
    html, body {
        width: 100%;
        margin: 0px;    /* ios only */
        padding: 0px;   /* ios only */
        height: 100%;
    }

    #app {
        width: 100%;
        margin: 0px;    /* ios only */
        padding: 0px;   /* ios only */
        height: 100%;
        /* overflow-y: hidden; */
    }

    .vdr {
        position: absolute;
        top: 13%;
        right: 4%;
        border: none!important;
    }

    .grow-enter-active, .grow-leave-active {
        transition: transform .5s;
    }
    .grow-enter, .grow-leave-to {
        transform: scale(0);
    }

    .button {
        background: #010101;
        border: 4px solid #3a1c06;
        padding: 26px 20px;
        color: #fff;
        font-size: 32px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 1px rgb(0, 0, 0);
        cursor: pointer;
    }

    .button-circle {
        background: #fff;
        border: 4px solid #3a1c06;
        color: #606061;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        text-align: center;
        padding: 2px 2px;
        font-size: 2em;
        box-shadow: 0px 0px 10px 1px rgb(0, 0, 0);
        cursor: pointer;
    }

    .text-input {
        background: #7e2d2b;
        color: #fff;
        border: 4px solid #b17f47;
        border-radius: 10px;
        padding: 12px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
        transform: translateZ(0);
    }
    ::-webkit-scrollbar-thumb {
        background: #a69272;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
    }

    @font-face {
        font-family: "CFABold";
        src: url("~@/assets/fonts/CFAstyStd-Bold.eot") format('embedded-opentype'),
        url("~@/assets/fonts/CFAstyStd-Bold.ttf") format("trueType"),
        url("~@/assets/fonts/CFAstyStd-Bold.woff") format("woff")
    }

    @font-face {
        font-family: "MyriadPro";
        src: url("~@/assets/fonts/MyriadPro-Bold.eot") format('embedded-opentype'),
        url("~@/assets/fonts/MyriadPro-Bold.ttf") format("trueType"),
        url("~@/assets/fonts/MyriadPro-Bold.woff2") format("woff2")
    }

    @font-face {
        font-family: "CFALight";
        src: url("~@/assets/fonts/CFAstyStd-Light.eot") format('embedded-opentype'),
        url("~@/assets/fonts/CFAstyStd-Light.ttf") format("trueType"),
        url("~@/assets/fonts/CFAstyStd-Light.woff") format("woff")
    }

</style>
