<template>
    <div class="carousel-container" :style="{height: carouselHeight}">
        <spinner v-if="spirits.length<1" class="spinner" />
        <div v-if="spirits.length<1" class="no-similar-spirits">{{ $t('SpiritCarousel.noSimilarSpirits') }}</div>
        <vueper-slides
            v-if="spirits.length>0"
            slide-multiple
            :bullets="false"
            :infinite="false"
            :touchable="false"
            :fixed-height="carouselHeight"
            class="no-shadow cont"
        >
            <vueper-slide
                v-for="(bucket, index) in buckets"
                :key="index"
            >
                <template #content>
                    <div
                        class="spirits-container"
                    >
                        <spirit-carousel-card
                            v-for="spirit in bucket"
                            :key="spirit.id"
                            :spirit="spirit"
                            :card-height="cardHeight"
                            @click.native="gotToSpirit(spirit)"
                        />
                    </div>
                </template>
            </vueper-slide>
            <template #arrow-left>
                <img :src="require('@/assets/common/previous_arrow_image.png')" alt="previous button" class="arrow">
            </template>
            <template #arrow-right>
                <img :src="require('@/assets/common/next_arrow_image.png')" alt="next button" class="arrow">
            </template>
        </vueper-slides>
    </div>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import SpiritCarouselCard from '@/components/spirit-mastery/SpiritCarouselCard'
    import Spinner from '@/components/Spinner'

    export default {
        components: {
            VueperSlides,
            VueperSlide,
            SpiritCarouselCard,
            Spinner
        },
        props: {
            spirits: {
                type: Array,
                required: true
            },
            bucketSize: {
                type: Number,
                required: false,
                default: 3
            },
            carouselHeight: {
                type: String,
                required: false,
                default: '27rem'
            },
            cardHeight: {
                type: String,
                required: false,
                default: '360px'
            },
            routePrefix: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                buckets: []
            }
        },
        watch: {
            spirits: {
                immediate: true,
                handler(newVal) {
                    this.preprocessSpirits()
                }
            }
        },
        created() {
            this.preprocessSpirits()
        },
        methods: {
            gotToSpirit(spirit) {
                this.$router.push({ path: `${this.routePrefix}/${spirit.id}` })
            },
            preprocessSpirits() {
                let index = 1
                let bucket = []
                this.buckets = []
                for (const spirit of this.spirits) {
                    bucket.push(spirit)
                    if (index % this.bucketSize === 0) {
                        this.buckets.push(bucket)
                        bucket = []
                    }
                    index++
                }
                if (bucket.length > 0) {
                    this.buckets.push(bucket)
                }
            }
        }
    }
</script>

<style lagn="scss" scoped>
.carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.spinner {
    align-self: center;
    margin-top: 4rem;
}
.spirits-container {
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.cont {
    width: 100%;
}

.arrow {
    width: 80%;
}

.no-similar-spirits {
    text-align: center;
    font-size: 17px;
    padding-top: 4rem;
}

</style>
