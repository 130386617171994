import wineService from '@/services/wine-service'

export default {
    state: {
        filters: []
    },
    mutations: {
        setFilters(state, filters) {
            state.filters = filters
        },
        setWineSelectedFilter({ filters }, filter) {
            filters.forEach((item, index) => {
                if (item.id === filter.id) {
                    filters[index].selectedId = filter.selectedOption
                }
            })
        },
        clearFilters({ filters }) {
            filters.forEach((item, index) => {
                filters[index].selectedId = 0
            })
        }
    },
    actions: {
        async fetchWineFilters({ commit }) {
            const filters = []
            const promises = []

            promises.push(wineService.getColors())
            promises.push(wineService.getTypes())
            promises.push(wineService.getDryness())
            promises.push(wineService.getBottleVolumes())
            promises.push(wineService.getSpecialTypes())
            promises.push(wineService.getOrigins())
            promises.push(wineService.getVarieties())

            Promise.all(promises).then(filterResponses => {
                // Wine Colors
                filters.push({
                    name: 'ΧΡΩΜΑ',
                    id: 'wineColor',
                    selectedId: 0,
                    options: filterResponses[0].content
                })

                // Wine Variety
                filters.push({
                    name: 'ΤΥΠΟΣ',
                    id: 'wineType',
                    selectedId: 0,
                    options: filterResponses[1].content
                })

                // Wine Dryness
                filters.push({
                    name: 'ΞΗΡΟΤΗΤΑ',
                    id: 'wineDryness',
                    selectedId: 0,
                    options: filterResponses[2].content
                })

                // Wine Volumes
                const wineVolumesTempt = filterResponses[3].content
                let wineVolumes = []
                wineVolumesTempt.forEach((wineVolume) => {
                    wineVolumes.push({
                        name: wineVolume.volume,
                        ...wineVolume
                    })
                })

                wineVolumes = wineVolumes.sort((volume1, volume2) => {
                    volume1 = volume1.name.replace(',', '.')
                    volume2 = volume2.name.replace(',', '.')
                    volume1 = (volume1.substr(-1, 2) === 'L') ? parseFloat(volume1) * 1000 : parseFloat(volume1)
                    volume2 = (volume2.substr(-1, 2) === 'L') ? parseFloat(volume2) * 1000 : parseFloat(volume2)

                    return volume1 === volume2 ? 0 : (volume1 > volume2) ? 1 : -1
                })
                filters.push({
                    name: 'ΣΥΣΚΕΥΑΣΙΑ',
                    id: 'wineBottleVolume',
                    selectedId: 0,
                    options: wineVolumes
                })

                // Wine Special Types
                filters.push({
                    name: 'ΙΔΙΑΙΤΕΡΕΣ ΠΛΗΡΟΦΟΡΙΕΣ',
                    id: 'wineSpecialType',
                    selectedId: 0,
                    options: filterResponses[4].content
                })

                // Wine Origins
                filters.push({
                    name: 'ΠΡΟΕΛΕΥΣΗ',
                    id: 'wineOrigin',
                    selectedId: 0,
                    options: filterResponses[5].content
                })

                // Wine Single Variety
                filters.push({
                    name: 'ΜΟΝΟΠΟΙΚΙΛΙΑΚΟ',
                    id: 'singleVariety',
                    selectedId: 0,
                    options: [
                        {
                            id: true,
                            name: 'Ναι'
                        },
                        {
                            id: false,
                            name: 'Όχι'
                        }
                    ]
                })

                const varieties = filterResponses[6].content.sort((option1, option2) =>
                    option1.name > option2.name ? 1 : -1
                )
                filters.push({
                    name: 'ΠΟΙΚΙΛΙΑ',
                    id: 'varieties',
                    selectedId: 0,
                    options: varieties,
                    multipleSelect: true,
                    ignore: true
                })

                commit('setFilters', filters)
            })
        },
        clearFilters({ commit }) {
            commit('clearFilters')
        }
    },
    getters: {
        wineFilters: (state) => {
            return state.filters
        },

        wineSelectedFilters: (state) => {
            const selectedFilters = []

            state.filters.forEach((filter, index) => {
                if (filter.selectedId !== 0 && filter.selectedId !== null) {
                    selectedFilters.push({
                        filterId: filter.id,
                        selectedValue: filter.selectedId,
                        ...filter
                    })
                }
            })

            return selectedFilters
        }
    }
}
