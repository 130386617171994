<template>
    <div v-if="wine" :style="{height:`${cardHeight}`}" class="wine-card mt-4">
        <div class="img-container">
            <img :src="wine | avatarBuilder('wine')" class="wine-img" alt="wine image">
        </div>
        <div class="wine-info ml-3 mt-2">
            <span class="wine-title">
                {{ wine.commercialName | nullCheck }}
            </span>
            <span v-if="wine.wineColor" class="wine-volume-label">
                {{ labelNameSetter(wine.wineColor.name) }}
                {{ wine.wineBottleVolume.volume }}
            </span>
            <span class="wine-supplier">
                {{ wine.winery? '-' + wine.winery + '-' : '' }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            wine: {
                type: Object,
                required: true
            },
            cardHeight: {
                type: String,
                required: true
            }
        },
        methods: {
            labelNameSetter(name) {
                if (name === 'Ερυθρό') {
                    return 'ΟΙΝΟΣ ΕΡΥΘΡΟΣ'
                } else if (name === 'Λευκό') {
                    return 'ΟΙΝΟΣ ΛΕΥΚΟΣ'
                } else if (name === 'Ροζέ') {
                    return 'ΟΙΝΟΣ ΡΟΖΕ'
                } else {
                    return ''
                }
            }
        }
    }
</script>

<style scoped>
.wine-card {
    width: 100%;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    text-align: center;
}

.img-container {
    width: 100%;
    height: 65%;
    align-self: center;
    filter: drop-shadow(1.2rem 0.6rem 0.5rem rgba(27, 1, 1, 0.3));
}

.wine-img {
    width: auto;
    height: 100%;
}

.wine-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: .7rem;
}
a:hover {
    color: inherit;
    text-decoration: none;
}

.wine-title {
    color: #ac3846;
    font-family: CFABold;
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.wine-volume-label {
    color: #000;
    font-family: CFALight;
    font-size: 0.875rem;
}

.wine-supplier {
    color: #ac7f5e;
    font-family: CFALight;
    font-size: 0.875rem;
}

.wine-color-text {
    font-family: CFABold;
    color: #59595b;
    font-size: clamp(15px, 1.6vw, 18px);
}
</style>
