<template>
    <div class="outer-container" @click.self="close">
        <div class="modal">
            <img
                :src="require('@/assets/tips/close-btn.png')"
                alt="close"
                class="close-btn"
                @click="close"
            >
            <h1 class="modal-title">{{ $t('Tips.header') }}</h1>
            <h3 class="tip-title">
                {{ tip.title }}
            </h3>
            <p class="tip-content">
                {{ tip.content }}
            </p>
            <img :src="require('@/assets/tips/tips-lampis.png')" alt="lampis" class="tip-image">
            <div class="disclaimer">{{ $t('EnjoyResponsibly.enjoyResponsibly') }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            tip() {
                return this.$store.getters.tip(this.$route.meta.tipType)
            }
        },
        methods: {
            close() {
                this.$store.commit('setTipModalOpen', false)
                this.$store.commit('removeTipByType', this.tip.type)
            }
        }
    }
</script>

<style scoped>
.outer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.modal {
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    box-shadow: 0px 0px 1500px 150px #3d3d3d;
    background: url('~@/assets/tips/tips-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: calc(100vh - 4rem);
    width: calc(100vw - 4rem);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.close-btn {
    position: absolute;
    cursor: pointer;
    top: 2%;
    right: 4%;
    width: 67px;
    height: 67px;
}

.modal-title {
    margin-top: 8rem;
    color: #7f2f2d;
    font-size: 4rem;
    font-family: "CFABold";
}

.tip-title {
    margin-top: 3rem;
    color: #ac3846;
    font-size: 2.25rem;
    font-family: "MyriadPro";
    text-align: center;
    max-width: 60%;
}

.tip-content {
    margin-top: 3rem;
    font-family: "CFALight";
    text-align: center;
    font-size: 1.5rem;
    line-height: 2.7rem;
    max-width: 80%;
}

.tip-image {
    margin-top: auto;
}

.disclaimer {
    font-size: 0.8125rem;
    color: #282828;
    font-family: "CFALight";
    position: absolute;
    right: 15px;
    bottom: 5px;
}
</style>
