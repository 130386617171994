<template>
    <div class="main-background">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <div class="header"> {{ $t('SommelierRoute.header') }}</div>
        <div class="link-cards-container">
            <router-link class="food-combination-wrapper mr-3" to="/sommelier/wine-food-combinations">
                <span class="link-logo harmony-logo"> {{ $t('SommelierRoute.foodCombinationLogo[0]') }} </span>
                <div class="link-logo">
                    <span class="highlight"> {{ $t('SommelierRoute.foodCombinationLogo[1]') }} </span>
                    <span> {{ $t('SommelierRoute.foodCombinationLogo[2]') }} </span>
                    <span class=" highlight"> {{ $t('SommelierRoute.foodCombinationLogo[3]') }} </span>
                </div>
                <div class="d-flex justify-content-between">
                    <span class="link-main-text"> {{ $t('SommelierRoute.foodCombinationPhrase') }} </span>
                    <div class="d-flex align-items-center">
                        <img class="arrow" src="@/assets/common/tile-light-arrow.png">
                    </div>
                </div>
            </router-link>
            <router-link class="wine-secrets-wrapper ml-3" to="/sommelier/wine-secrets">
                <div class="link-logo mb-4">
                    <span class="highlight"> {{ $t('SommelierRoute.wineSecretsLogo[0]') }} </span>
                    <span> {{ $t('SommelierRoute.wineSecretsLogo[1]') }} </span>
                </div>
                <div class="d-flex justify-content-between">
                    <span class="link-main-text"> {{ $t('SommelierRoute.wineSecretsPhrase') }} </span>
                    <div class="d-flex align-items-center">
                        <img class="arrow" src="@/assets/common/tile-light-arrow.png">
                    </div>
                </div>
            </router-link>
        </div>
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-roads" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('FooterTile.wineRoadsTittle')" :text="$t('FooterTile.wineRoadsText')" theme="white" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.wineMastery')" :text="$t('WineRoadsRoute.chοoseWine')" theme="yellow" />
                </div>
            </router-link>
        </div>
        <enjoy-responsibly :bottom="'20rem'" />
    </div>
</template>
<script>
    import Breadcrumb from '@/components/Breadcrumb'
    import foodCombinationService from '@/services/food-combo-service'
    import FooterTile from '@/components/FooterTile'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, FooterTile, EnjoyResponsibly },
        data() {
            return {
                path: []
            }
        },
        created() {
            foodCombinationService.getBreadcrumbPath([{ name: 'AB Sommelier', path: '/sommelier' }])
                .then((resp) => {
                    this.path = resp
                })
        }
    }
</script>

<style scoped>

.main-background {
    background-image: url('~@/assets/sommelier/main_sommelier_background.png');
    height: 100%;
    position: relative;
}

.header {
    text-align: center;
    font-family: CFABold, sans-serif;
    font-size: 60px;
    color: #792a2a;
    margin-top: 3%;
}

.link-cards-container {
    position: absolute;
    bottom: 22%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.food-combination-wrapper,
.wine-secrets-wrapper {
    width: 40%;
    height: 250px;
    padding: 2rem 2rem;
}

.food-combination-wrapper {
    background-image: url('~@/assets/sommelier/food_combination_link.png');
    background-size: 100% 100%;
    text-decoration: none;
}
.link-logo {
    font-size: 20px;
    color: #e4e1db;
    font-family: CFABold, sans-serif;
}
.harmony-logo {
    font-size: 20px;
}
.highlight{
    font-size: 20px;
    color: #d79b4c;
}

.link-main-text {
    margin-top: 1rem;
    font-family: CFABold, sans-serif;
    font-size: 2.2rem;
    max-width: 200px;
    line-height: 2rem;
    color: #e4e1db;
}

.wine-secrets-wrapper {
    background-image: url('~@/assets/sommelier/wine_secrets_link.png');
    background-size: 100% 100%;
    text-decoration: none;
}

.wine-roads-container {
    display: flex;
    width: 100%;
    height: 10vh;
    position: absolute;
    bottom: 2%;
}

.footer-tile {
    height: 100%;
    width: 100%;
}
</style>
