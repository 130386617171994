<template>
    <div v-if="wineRegion" class="background-wrapper">
        <div class="background">
            <breadcrumb :path="path" :is-dark="true">
                <img :src="require('@/assets/common/home-button.png')">
            </breadcrumb>
            <wine-region-info :wine-region="wineRegion" class="wine-region-info" />
            <div class="region-trivia-container">
                <wine-trivia-card
                    :trivia-data="wineRegion.children.length.toString()"
                    :trivia-text="wineRegion.children.length !== 1 ?$t('WineRoadsStep2.winefields') : $t('WineRoadsStep2.winefield')"
                >
                    <img src="@/assets/wine-roads/winefields.png" alt="winefield">
                </wine-trivia-card>
                <wine-trivia-card
                    v-if="regionWines"
                    :trivia-data="regionWines.numberOfElements.toString()"
                    :trivia-text="regionWines.numberOfElements !== 1 ? $t('WineRoadsStep2.wineEtiquetes') : $t('WineRoadsStep2.wineEtiquete')"
                >
                    <img src="@/assets/wine-roads/wine-count.png" alt="wine-count">
                </wine-trivia-card>
                <wine-trivia-card
                    :trivia-data="wineRegion.extraRegionInfoTop"
                    :trivia-text="wineRegion.extraRegionInfoBottom"
                >
                    <img src="@/assets/wine-roads/grapes.png" alt="greek-varieties">
                </wine-trivia-card>
            </div>
        </div>
        <div class="winefields-number-circle">
            <p class="winefields-number"> {{ wineRegion.children.length }} </p>
        </div>
        <p class="winefields-text"> {{ wineRegion.children.length !== 1 ? $t('WineRoadsStep2.winefields') : $t('WineRoadsStep2.winefield') }} </p>
        <div class="wine-regions-container">
            <div v-if="wineRegion && wineRegion.children" class="wine-regions">
                <router-link
                    v-for="child of wineRegion.children"
                    :key="child.id"
                    :to="routerTo(child)"
                    class="wine-region"
                >
                    <span class="region-childs"> {{ child.name }} </span>
                </router-link>
            </div>
        </div>
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/sommelier/wine-secrets" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="'AB SOMMELIER'" :text="'Ανακαλύπτω οινικά μυστικά'" theme="green" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="'WINE MASTERY'" :text="'Επιλέγω  το κρασί που μου ταιριάζει'" theme="yellow" />
                </div>
            </router-link>
        </div>
        <enjoy-responsibly :bottom="'20rem'" />
    </div>
</template>
<script>
    import wineRegionService from '@/services/wine-region-service'
    import WineRegionInfo from '@/components/wine-roads/WineRegionInfo'
    import FooterTile from '@/components/FooterTile'
    import Breadcrumb from '@/components/Breadcrumb'
    import WineTriviaCard from '@/components/wine-roads/WineTriviaCard'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, WineRegionInfo, FooterTile, WineTriviaCard, EnjoyResponsibly },
        data() {
            return {
                wineRegion: null,
                regionWines: null,
                path: [],
                rootPath: [{ name: 'Δρόμοι του Κρασιού', path: '/wine-roads' }]
            }
        },
        async created() {
            const wineRegion = await wineRegionService.getWineRegionById(this.$route.params.regionId)
            this.wineRegion = wineRegion

            const regionalWines = await wineRegionService.getWinesByRegionId(this.$route.params.regionId)
            this.regionWines = regionalWines

            const path = await wineRegionService.getBreadcrumbPath(this.rootPath, this.wineRegion)
            this.path = path
        },
        methods: {
            routerTo(region) {
                return `/wine-roads/${region.step}/${region.id}`
            }
        }
    }
</script>
<style scoped>
.background-wrapper{
    height: 100%;
    position: relative;
}
.background {
    background: url('~@/assets/wine-roads/step2-background.jpg');
    display: flex;
    flex-direction: column;
    margin: auto;
}
.wine-region-info {
    margin-top: 1rem;
}
.region-trivia-container {
    margin-top: 2%;
    background-color: #882c2e;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
}

.winefields-number-circle   {
    margin: 10% auto 0 auto;
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    background-color: #7f2f2d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.winefields-number {
    color: white;
    font-size: 2rem;
    margin: 0;
    font-family: "CFABold", sans-serif;
}
.winefields-text {
    text-align: center;
    color: #7f2f2d;
    margin-top: 2%;
    margin-bottom: .8rem;
    font-size: 1.75rem;
    font-family: "CFABold", sans-serif;
}
.wine-regions-container {
    padding: 1rem 0;
    min-height: 22rem;
}
.wine-regions {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 0.5rem;
    padding: 0 1rem;

}
.wine-region {
    border: 0.18rem solid #7d7d64;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5d5d4a;
    text-decoration: none;
    width: 14rem;
    text-align: center;
    line-height: 1.3rem;
    padding: 0 .2rem;
}
.region-childs {
    font-size: 1.2rem;
    font-family: "CFABold", sans-serif;
}
.wine-roads-container {
    display: flex;
    padding-top: 2rem;
    height: 11.5vh;
}
.footer-tile {
    height: 100%;
    width: 100%;
}

</style>
