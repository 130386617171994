<template>
    <div class="trivia-wrapper">
        <slot />
        <p class="trivia-numbers"> {{ triviaData || '-' }} </p>
        <p class="trivia"> {{ triviaText }} </p>
    </div>
</template>

<script>
    export default {
        props: {
            triviaData: {
                type: String,
                default: ''
            },
            triviaText: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped>
.trivia-wrapper {
    width: 25%;
    text-align: center;
}
.trivia-wrapper img{
    margin-bottom: 0.3rem;
}
.trivia-numbers,
.trivia {
    text-align: center;
    margin: 0 auto ;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    justify-content: center;
    font-family: "CFALight", sans-serif;
}
.trivia-numbers {
    font-size: 2rem;
    font-weight: 700;
}
.trivia {
    font-size: 1.15rem;

}
</style>
