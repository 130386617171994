export default {
    state: {
        keyboardVisible: false
    },
    mutations: {
        setKeyboardVisibility(state, value) {
            state.keyboardVisible = value
        }
    },
    actions: {
        showKeyboard({ commit }) {
            commit('setKeyboardVisibility', true)
        },
        hideKeyboard({ commit }) {
            commit('setKeyboardVisibility', false)
        }
    },
    getters: {
        keyboardVisible: state => state.keyboardVisible
    }
}
