<template>
    <div>
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-roads" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('FooterTile.wineRoadsTittle')" :text="$t('FooterTile.wineRoadsText')" theme="white" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.wineMastery')" :text="$t('WineRoadsRoute.chοoseWine')" theme="yellow" />
                </div>
            </router-link>
        </div>
        <div class="enjoy-responsibly" :style="{bottom: bottom, right: right}">
            {{ $t('EnjoyResponsibly.enjoyResponsibly') }}
        </div>
    </div>
</template>

<script>
    import FooterTile from '@/components/FooterTile'

    export default {
        components: { FooterTile },
        data() {
            return {
            }
        }
    }
</script>

<style scoped>

.wine-roads-container {
    display: flex;
    width: 100%;
    height: 10vh;
    position: absolute;
    bottom: 2%;
}

.footer-tile {
    height: 100%;
    width: 100%;
}

.enjoy-responsibly {
    position: fixed;
    color: #a7a7a6;
    font-size: 1rem;
    font-family: CFALight;
    font-weight: 600;
    right: 10px;
    bottom: 10px;
}

</style>
