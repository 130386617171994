import tipService from '@/services/tip-service'

export default {
    state: {
        tips: [],
        tipModalOpen: false
    },
    mutations: {
        setTipModalOpen(state, value) {
            state.tipModalOpen = value
        },
        setTips(state, tips) {
            state.tips = tips
        },
        removeTipByType(state, tipType) {
            state.tips = state.tips.filter(tip => tip.type !== tipType)
        }
    },
    actions: {
        async fetchRandomTips({ commit }) {
            const tips = await tipService.getRandomTips()
            commit('setTips', tips.content)
        }
    },
    getters: {
        tips: state => state.tips,
        tipModalOpen: state => state.tipModalOpen,
        tip: state => type => state.tips.find(tip => tip.type === type)
    }
}
