<template>
    <div class="header-container">
        <div class="nav-back-container ml-2">
            <div v-if="$route.name !== 'WineMasteryRoute' && $route.name !== 'SpiritMasteryRoute'" class="bck-btn mr-4" @click="goBack()">
                <img :src="require('@/assets/common/goback_image.png')">
            </div>
            <div class="bck-btn" @click="goHome()">
                <img :src="require('@/assets/common/home-button.png')">
            </div>
        </div>
        <router-link v-slot="{ navigate }" :to="{ name: `${targetRoute}`, params: { fromHeader: true }}" class="pointer-cursor" custom>
            <div class="scan-container" @click="navigate" @keypress.enter="navigate">
                <img :src="require('@/assets/common/barcode_image.png')" class="scan-img">
                <span v-if="targetRoute==='WineScanRoute'" class="common-text">
                    {{ $t('HeaderBar.scanWine') }}
                </span>
                <span v-else class="common-text">
                    {{ $t('HeaderBar.scanSpirit') }}
                </span>
            </div>
        </router-link>
        <spirit-search-input
            v-if="targetRoute==='SpiritScanRoute'"
            class="mr-2"
            :focus="focus"
        />
        <wine-search-input
            v-else
            class="mr-2"
            :focus="focus"
        />
    </div>
</template>

<script>
    import WineSearchInput from '@/components/WineSearchInput'
    import SpiritSearchInput from '@/components/SpiritSearchInput'
    export default {
        components: {
            WineSearchInput,
            SpiritSearchInput
        },
        props: {
            focus: {
                type: Boolean,
                required: false,
                default() {
                    return false
                }
            },
            targetRoute: {
                type: String,
                required: false,
                default: 'WineScanRoute'
            }
        },
        methods: {
            goBack() {
                if (this.$store.getters.keyboardVisible) {
                    this.$store.dispatch('hideKeyboard')
                }
                return this.$router.go(-1)
            },
            goHome() {
                if (this.$store.getters.keyboardVisible) {
                    this.$store.dispatch('hideKeyboard')
                }
                this.$router.push({ path: '/home' })
            }
        }
    }
</script>

<style lang="scss" scoped>

.nav-back-container{
    display:flex;
    align-items: center;
}

.header-container {
  display: flex;
  align-items: center;
  color: #59595b;
  width: 100%;
  height: 153px;
  justify-content: space-between;
}

.common-text {
  font-family: CFAlight;
  font-size: clamp(15px,2.4vw,26px);
}

.scan-img {
  padding: 4px 10px;
}

button:focus {outline:0!important;}
.bck-btn {
  display: flex;
  align-items: center;
  color: #59595b;
}

.pointer-cursor {
    cursor: pointer;
}

</style>
