<template>
    <div class="outer-container">
        <div v-if="wine.foodCombinations.length!==0" class="title">{{ $t('FoodPairing.goesWith') }}</div>
        <div
            v-if="wine.foodCombinations.length!==0"
            class="container"
        >
            <div
                v-for="foodCombination in wine.foodCombinations"
                :key="foodCombination.id"
            >
                <div v-if="foodCombination.platePhoto!==null" class="flex-item">
                    <img :src="config.host + '/' + foodCombination.platePhoto.relativePath + '/' + foodCombination.platePhoto.fileName">
                    <div class="food-type">{{ foodCombination.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            wine: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
.outer-container {
    height: 380px;
}

.container {
    background: url('~@/assets/wine-mastery/background_foodpairing.png');
    background-position: center ;
    background-repeat: no-repeat;
    max-width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
}

.flex-item {
    padding: 1rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-item img {
    width: 11rem;
    filter: drop-shadow(1.2rem -0.037rem 1rem rgba(27, 1, 1, 0.5));
}

.title {
    font-size: 2.8rem;
    font-family: CFABold;
    text-align: center;
    color: #ac3846;
}

.food-type {
    color: #fff;
    font-size: 1.2rem;
    font-family: CFALight;
    font-weight: 600;
    color: #fff;
    padding-top: 0.7rem;
    padding-bottom: 3rem;
    text-align: center;
}

</style>
