<template>
    <div v-if="spirit" :style="{height:`${cardHeight}`}" class="spirit-card mt-4">
        <div class="img-container">
            <img :src="spirit | avatarBuilder('spirit')" class="spirit-img">
        </div>
        <div class="spirit-info ml-3 mt-2">
            <span class="spirit-title">
                {{ spirit.commercialName | nullCheck }}
            </span>
            <span class="spirit-volume-label">
                {{ spirit.category.name.toUpperCase() | nullCheck }}
                {{ spirit.bottleVolume.volume.toUpperCase() | nullCheck }}
            </span>
            <span class="spirit-supplier">
                {{ spirit.distillingCompany ? `- ${spirit.distillingCompany} -` : '' }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            spirit: {
                type: Object,
                required: true
            },
            cardHeight: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
.spirit-card {
    width: 100%;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    text-align: center;
}

.img-container {
    width: 100%;
    height: 65%;
    align-self: center;
    filter: drop-shadow(1.2rem 0.6rem 0.5rem rgba(27, 1, 1, 0.3));
}

.spirit-img {
    width: auto;
    height: 100%;
}

.spirit-info {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: .7rem;
}
a:hover {
    color: inherit;
    text-decoration: none;
}

.spirit-title {
    color: #ac3846;
    font-family: CFABold;
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.spirit-volume-label {
    color: #000;
    font-family: CFALight;
    font-size: 0.875rem;
}

.spirit-supplier {
    color: #ac7f5e;
    font-family: CFALight;
    font-size: 0.875rem;
}

.spirit-color-text {
    font-family: CFABold;
    color: #59595b;
    font-size: clamp(15px, 1.6vw, 18px);
}
</style>
