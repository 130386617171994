import axios from 'axios'
import spiritService from '@/services/spirit-service'

export default {
    state: {
        spiritSearchResults: [],
        spiritSearchQuery: '',
        cancelToken: {}
    },
    mutations: {
        setResult(state, spirits) {
            state.spiritSearchResults = spirits
        },
        setSpiritSearchQuery(state, query) {
            state.spiritSearchQuery = query
        },
        setCancelToken(state, token) {
            state.cancelToken = token
        }
    },
    actions: {
        async searchSpirits({ commit, state }, query) {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()

            if (state.cancelToken.cancel) {
                state.cancelToken.cancel('')
            }
            const filterObject = {
                q: query,
                published: true
            }
            const spirits = (await spiritService.getAll({ filter: filterObject }, source.token)).content

            commit('setCancelToken', source)
            commit('setResult', spirits)
        }
    },
    getters: {
        spiritSearchResults: (state) => {
            return state.spiritSearchResults
        },
        spiritSearchQuery: (state) => {
            return state.spiritSearchQuery
        }
    }
}
