<template>
    <div class="scanPage" @click="focusInput">
        <div class="popup">
            <div class="background">
                <div class="body">
                    <div class="close2" @click="$router.go(-1);">
                        <img :src="require('@/assets/wine-mastery/back_button.png')">
                    </div>
                    <div class="container">
                        <div class="barcode-icon">
                            <img :src="require('@/assets/common/barcode_image.png')">
                        </div>
                        <div class="title">{{ $t('WineScanRoute.scanWine') }}</div>
                        <div v-if="!scanWasNotFound" class="text-center">
                            <div class="scan-indicator">
                                <img :src="require('@/assets/wine-mastery/scan_waiting.png')" class="scan-status-img">
                            </div>
                            <span>{{ $t('WineScanRoute.scanBarcode[0]') }}<br> {{ $t('WineScanRoute.scanBarcode[1]') }}</span>
                        </div>
                        <div v-else class="text-center">
                            <div class="scan-indicator">
                                <img :src="require('@/assets/wine-mastery/scan_not_found.png')">
                            </div>
                            <span>{{ $t('WineScanRoute.productNotFound') }} <br> {{ $t('WineScanRoute.tryAgain') }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input
            ref="barcode"
            v-model="barcode"
            type="text"
            class="barcode-input"
            @keydown.enter="scan"
        >
        <div class="legal">
            {{ $t('EnjoyResponsibly.enjoyResponsibly') }}
        </div>
    </div>
</template>

<script>
    import scanService from '@/services/scan-service'
    import eventBus from '@/utils/event-bus'

    export default {
        data() {
            return {
                scanned: null,
                scanWasNotFound: false,
                barcode: '',
                timeOut: null
            }
        },
        watch: {
            scanned(newVal) {
                if (newVal.type === 'spirit') {
                    this.$router.push({ path: `/spirit-mastery/${newVal.id}`, query: { fromScan: true } })
                } else if (newVal.type === 'wine') {
                    this.$router.push({ path: `/wine-mastery/wines/${newVal.id}`, query: { fromScan: true } })
                }
            }
        },
        mounted() {
            this.$store.commit('setWineSearchQuery', '')
            eventBus.emit('clearSearch')
            this.$store.dispatch('hideKeyboard')
            this.focusInput()
        },
        methods: {
            focusInput() {
                if (this.$refs.barcode) {
                    this.$refs.barcode.focus()
                }
            },
            scan() {
                scanService.scan(this.barcode).then(scanned => {
                    this.scanned = scanned
                }).catch(() => {
                    this.displayNotFound()
                })
            },
            displayNotFound() {
                this.scanWasNotFound = true
                this.barcode = ''
                clearTimeout(this.timeOut)
                this.timeOut = setTimeout(() => {
                    this.scanWasNotFound = false
                }, 5 * 1000)
            }
        }
    }
</script>

<style lang="scss" scoped>
.scanPage {
  background: url('~@/assets/common/background_image.png');
  width: 100%;
  height: 100%;
}

.popup {
  position: relative;
}

.popup .background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, .6);
}

.title {
  color: #595a5b;
  font-size: 2.1rem;
  font-weight: 600;
  font-family: CFALight;
}

.popup .body {
  background: url('~@/assets/common/background_image.png');
  background-size: cover;
  padding: 2.81rem 2.5rem;
  margin:  5.4rem auto;
  border-radius: 0.625rem;
  box-shadow: 0 1.125rem 3.69rem rgba(0,0,0,0.54);
  line-height: 4.75rem;
  font-weight: 300;
  width: 85vw;
  height: 90vh;
  max-width: 1300px;
  max-height: 1920px;
}

.popup .body span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-top: 5%;
  color: #ac3846;
  font-size: 2rem;
  text-align: center;
  font-family: CFABold;
}

.popup .close2 {
  float: right;
  cursor: pointer;
}

.close2 img {
  width: 3.75rem;
  height: 3.75rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barcode-icon {
  margin: auto;
  margin-top: 6.25rem;
  margin-bottom: 1.25rem;
}

.barcode-icon img{
  width: 5.375rem;
  height: 5.375rem;
}

.scan-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-indicator img{
  width: 25rem;
  height: 35.813rem;
  margin-top: 6.25rem;
  margin-bottom: 6.875rem;
}

.barcode-input {
    opacity: 0;
    position: absolute;
    top: -20px;
    left: -20px;
}

.legal {
  display: block;
  position: fixed;
  bottom: 2.5rem;
  right: 5.875rem;
  color: #fff;
  font-size: .8em;
  font-weight: 600;
}
</style>
