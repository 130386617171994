export default function joinWines(wines, joinWines) {
    if (wines.length === 0) {
        return joinWines
    }

    const maxRank = 10
    const rankGroups = []
    for (let i = 0; i <= maxRank; i++) {
        rankGroups[i] = []
    }

    wines.forEach(wine => {
        rankGroups[wine.rank].push(wine)
    })
    joinWines.forEach(wine => {
        rankGroups[wine.rank].push(wine)
    })

    const joinedWines = []
    for (let i = 0; i <= maxRank; i++) {
        rankGroups[i].forEach(wine => {
            joinedWines.push(wine)
        })
    }

    return joinedWines
}
