const host = process.env.VUE_APP_HOST_DOMAIN

export function avatarBuilder(item, type) {
    return (item && item.avatarPhotos && item.avatarPhotos.length > 0) ? `${host}/${item.avatarPhotos[0].relativePath}/${item.avatarPhotos[0].fileName}` : require(`@/assets/common/default_${type}.png`)
}

export function nullCheck(value, keyPath) {
    keyPath = keyPath || ''
    if (keyPath.length > 0) {
        const keys = keyPath.split('.')
        let tempValue = value

        keys.forEach(key => {
            const val = tempValue ? tempValue[key] : tempValue
            if (val === null || typeof val === 'undefined') {
                tempValue = ''
            } else {
                tempValue = val
            }
        })

        return tempValue
    } else {
        return value === null || typeof value === 'undefined' ? '' : value
    }
}
