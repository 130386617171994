import restAPI from '@/utils/rest-api'
import axios from 'axios'

export default {
    getAll(requestData) {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        const filter = requestData.filter || {}
        const sort = requestData.sort || []
        const range = (requestData.range) ? JSON.stringify(requestData.range) : ''
        let url = `/spirits?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&range=${range}`
        if (requestData.applyClustering !== undefined) {
            url += `&applyClustering=${requestData.applyClustering}`
        }
        return restAPI.get(encodeURI(url), source.token)
    },
    getSpiritRootCategories() {
        return restAPI.get(encodeURI('/spirit-categories/roots'))
    },
    getSpiritCategoryById(categoryId) {
        return restAPI.get(encodeURI(`/spirit-categories/${categoryId}`))
    },
    getSpiritBottleVolumes() {
        return restAPI.get(encodeURI('/spirit-bottle-volumes?sort=["pos","ASC"]'))
    },
    getSpiritOrigins() {
        return restAPI.get(encodeURI('/spirit-origins?sort=["pos","ASC"]'))
    },
    get(id) {
        return restAPI.get(`/spirits/${id}`)
    }
}
