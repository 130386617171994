<template>
    <div class="page-container">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <h1 class="title">{{ $t('WineFoodCombinationStep1AllWinesRoute.allWines') }}</h1>
        <div class="total-wines">
            - {{ foodWines.length }} {{ $t('WineRoadsStep3AllWinesRoute.labels') }} -
        </div>
        <wine-carousel
            :wines="foodWines"
            :bucket-size="9"
            carousel-height="75vh"
            card-height="450px"
            route-prefix="/sommelier/wine-food-combinations"
            class="carousel"
        />
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-roads" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineFoodCombinationStep1AllWinesRoute.wineRoads')" :text="$t('WineFoodCombinationStep1AllWinesRoute.chooseVariety')" theme="white" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" custom to="/wine-mastery">
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineFoodCombinationStep1AllWinesRoute.wineMastery')" :text="$t('WineFoodCombinationStep1AllWinesRoute.chοoseWine')" theme="yellow" />
                </div>
            </router-link>
        </div>
        <enjoy-responsibly :bottom="'20rem'" />
        <disclaimer />
    </div>
</template>
<script>
    import WineCarousel from '@/components/WineCarousel.vue'
    import foodComboService from '@/services/food-combo-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import randomizeItems from '@/utils/randomize-items'
    import FooterTile from '@/components/FooterTile'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            Breadcrumb,
            WineCarousel,
            FooterTile,
            Disclaimer,
            EnjoyResponsibly
        },
        data() {
            return {
                foodWines: [],
                foodCombination: null,
                rootPath: [{ name: 'AB Sommelier', path: '/sommelier' }, { name: 'Αρμονία Κρασιού & Φαγητού', path: '/sommelier/wine-food-combinations' }],
                path: []
            }
        },
        async created() {
            this.foodCombination = await foodComboService.getFoodCombinationById(this.$route.params.foodId)
            this.fetchFoodWines(this.$route.params.foodId)
            this.createBreadcrumbPath()
        },
        methods: {
            createBreadcrumbPath() {
                foodComboService.getBreadcrumbPath(this.rootPath, this.$route.params.foodId)
                    .then((path) => {
                        this.path = [...path, { name: this.foodCombination.name, path: `/sommelier/wine-food-combinations/1/${this.$route.params.foodId}` }, { name: 'Ολα τα Κρασιά', path: this.$route.path }]
                    })
            },
            fetchFoodWines(foodId) {
                foodComboService.getWinesByFoodId({ foodId })
                    .then(res => {
                        this.foodWines = randomizeItems(res.content)
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
.page-container {
    background: url('~@/assets/wine-roads/all-wines-background.png');
}
.title {
    margin-top: 1.2rem;
    text-align: center;
    font-size: 3.5rem;
    color: #882c2e;
    font-family: 'CFABold';
}
.total-wines {
    text-align: center;
    font-family: 'CFALight';
    font-size: 1.2rem;
}
.carousel-container {
    margin-top: 2rem;
    height: 75vh;
}
.wine-roads-container {
    display: flex;
    height: 10vh;
}
.footer-tile {
    height: 100%;
    width: 100%;
}

</style>
