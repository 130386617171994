<template>
    <transition name="slide">
        <div
            class="keyboardContainer"
            @mouseover="hover = true"
            @mouseleave="hover = false"
        >
            <div class="keyboard-body">
                <div class="buttons-container">
                    <span class="keyboard-top-button" @click="switchLanguage()">
                        {{ language }}
                    </span>
                    <span
                        class="keyboard-top-button"
                        @click="hideKeyboard"
                    >
                        {{ languageCloseBtn }}
                        <font-awesome-icon icon="times" />
                    </span>
                </div>
                <div class="simple-keyboard" />
            </div>
        </div>
    </transition>
</template>

<script>
    import Keyboard from 'simple-keyboard'
    import 'simple-keyboard/build/css/index.css'
    import eventBus from '@/utils/event-bus'

    export default {
        data: () => ({
            keyboard: null,
            hover: false,
            language: 'ENGLISH',
            languageCloseBtn: 'ΚΛΕΙΣΙΜΟ',
            greek: {
                default: [
                    '1 2 3 4 5 6 7 8 9 0 - {bksp}',
                    '& ς ε ρ τ υ θ ι ο π',
                    '{lock} α σ δ φ γ η ξ κ λ',
                    'ζ χ ψ ω β ν μ {lock}',
                    '{space}'
                ],
                shift: [
                    '1 2 3 4 5 6 7 8 9 0 - {bksp}',
                    '& ς Ε Ρ Τ Υ Θ Ι Ο Π',
                    '{lock} Α Σ Δ Φ Γ Η Ξ Κ Λ',
                    'Ζ Χ Ψ Ω Β Ν Μ {lock}',
                    '{space}'
                ]
            },
            english: {
                default: [
                    '1 2 3 4 5 6 7 8 9 0 - {bksp}',
                    'q w e r t y u i o p',
                    'a s d f g h j k l',
                    'z x c v b n m {lock}',
                    '{space}'
                ],
                shift: [
                    '1 2 3 4 5 6 7 8 9 0 - {bksp}',
                    'Q W E R T Y U I O P',
                    'A S D F G H J K L',
                    'Z X C V B N M {lock}',
                    '{space}'
                ]
            }
        }),
        watch: {
            input(input) {
                this.keyboard.setInput(input)
            }
        },
        created() {
            eventBus.on('clearSearch', () => {
                this.keyboard.setInput('')
                this.onChange('')
            })
        },
        mounted() {
            this.keyboard = new Keyboard({
                onChange: this.onChange,
                onKeyPress: this.onKeyPress,
                theme: 'hg-theme-default myTheme1',
                layout: this.greek
            }
            )
        },
        methods: {
            switchLanguage() {
                if (this.language === 'ENGLISH') {
                    this.language = 'ΕΛΛΗΝΙΚΑ'
                    this.languageCloseBtn = 'CLOSE'
                    this.keyboard.setOptions({ layout: this.english })
                } else {
                    this.language = 'ENGLISH'
                    this.languageCloseBtn = 'ΚΛΕΙΣΙΜΟ'
                    this.keyboard.setOptions({ layout: this.greek })
                }
            },
            onChange(input) {
                this.$store.commit('setWineSearchQuery', input)
                this.$store.commit('setSpiritSearchQuery', input)
            },
            onKeyPress(button) {
                if (button === '{shift}' || button === '{lock}') this.handleShift()
            },
            handleShift() {
                const currentLayout = this.keyboard.options.layoutName
                const shiftToggle = currentLayout === 'default' ? 'shift' : 'default'

                this.keyboard.setOptions({
                    layoutName: shiftToggle
                })
            },
            hideKeyboard() {
                this.$store.dispatch('hideKeyboard')
            }
        }
    }
</script>

<style>
.keyboardContainer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0px;
    z-index: 99999999;
    background: rgba(1, 1, 1, .85);
    background: #7f2f2d;
    box-shadow: 0px -5px 10px #000;
}

.keyboard-body {
    margin: auto;
    box-shadow: 5px -0px 10px rgba(0,0,0,0.6);
}

.buttons-container{
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

.keyboard-top-button{
    background: #7f2f2d;
    padding: 8px;
    border: 2px solid #ac7f5e;
    color: #e8e5e4;
    width: 110px;
    text-align: center;
    cursor: pointer;
    display: block;
}

.simple-keyboard.myTheme1 {
  background-color: #ac7f5e;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7f2f2d;
  font-family: CFABold;
  color: #e8e5e4;
  border: none;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #fff;
  color: #000;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}

.slide-enter-active, .slide-leave-active {
  transition: bottom .3s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  bottom: -80%;
}
</style>
