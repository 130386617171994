<template>
    <div class="side-bar-container">
        <div
            v-for="foodCombination in rootFoodCombinations"
            :key="foodCombination.id"
            :class="['menu-item', {'active': foodCombination.id === activeFoodCombinationId}]"
            @click="selectRootFoodCombination(foodCombination.id)"
        >
            <img v-if="foodCombination.platePhoto" :src="config.host + '/' + foodCombination.platePhoto.relativePath + '/' + foodCombination.platePhoto.fileName" class="food-shadow">
            <img v-else :src="require('@/assets/sommelier/meat_food.png')" class="food-shadow">
            <div>{{ foodCombination.name }}</div>
        </div>
    </div>
</template>

<script>
    import foodComboService from '@/services/food-combo-service'
    import config from '@/mixins/config'
    export default {
        mixins: [config],
        props: {
            activeFoodCombinationId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                rootFoodCombinations: []
            }
        },
        created() {
            foodComboService.getRootFoodCombinations().then((res) => {
                this.rootFoodCombinations = res.content
            })
        },
        methods: {
            selectRootFoodCombination(foodCombinationId) {
                this.$emit('select', foodCombinationId)
            }
        }
    }
</script>

<style lang="scss" scoped>
.side-bar-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.menu-item {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #4f5051;
    font-family: 'CFABold';
}

.menu-item::before {
    content: '';
    position: absolute;
    left: -0.4rem;
    height: 100%;
    border: solid rgba(30,30,30,0.5);
    filter: blur(0.5rem);
}
.menu-item.active {
    background: #7f2f2d;
    color: #fff;
    box-shadow: none;
}
.menu-item.active::before {
    content: '';
    opacity: 0;
}
img {
    height: 8rem;
    width: 8rem;
}
.food-shadow {
    filter: drop-shadow(5px 0px 6px rgba(78,76,73,0.4));
}
</style>
