<template>
    <div class="list-background">
        <header-bar />
        <div>
            <div class="text-right d-xl-block d-none">
                <button
                    v-b-toggle.collapse-filters
                    class="filter-expand-button mt-2 mr-2"
                >
                    {{ $t('WineMasteryRoute.showFilters') }}
                </button>
            </div>
            <b-collapse id="collapse-filters" visible>
                <div class="filters-container py-3">
                    <div class="filters-top-container mx-3">
                        <h3 class="filter-header">{{ $t('WineMasteryRoute.filters') }}</h3>
                        <span v-show="$store.getters.wineSelectedFilters.length > 0" class="clear-filters" @click="clearFilters">
                            <font-awesome-icon class="close-icon" icon="times" />
                            {{ $t('WineMasteryRoute.clearFilters') }}
                        </span>
                    </div>
                    <div class="filters-bottom-container">
                        <div
                            v-for="filter, index in filters"
                            :key="index"
                            class="single-filter-container"
                        >
                            <h5 class="filter-name-text mt-3">
                                {{ filter.name }}
                            </h5>
                            <input-button-select
                                v-if="filter.options.length <= 4 && !filter.multipleSelect"
                                :options="filter.options"
                                :selected="filter.selectedId"
                                @change="optionId => changeSelectedFilter(filter.id, optionId)"
                            />
                            <input-select
                                v-else
                                :options="filter.options"
                                :selected="filter.selectedId"
                                @change="optionId => changeSelectedFilter(filter.id, optionId)"
                            />
                        </div>
                    </div>
                </div>
            </b-collapse>
            <div ref="wineList" class="wines-list" @scroll="scrollEvent">
                <div
                    v-for="wine, index in wines"
                    :key="index"
                    @click="viewWine(wine)"
                >
                    <wine-list-item :wine="wine" />
                </div>
                <b-col
                    v-if="wines.length === 0"
                    cols="12"
                    class="text-center pt-5"
                    style="font-size: 35px;font-weight:600;margin-top: 18%;"
                >
                    {{ $t('WineMasteryRoute.productsNotFound[0]') }} <br>{{
                        $t('WineMasteryRoute.productsNotFound[1]')
                    }}
                </b-col>
            </div>
        </div>
        <enjoy-responsibly />
        <disclaimer :background-color="'#f5f5f4'" />
    </div>
</template>

<script>
    import InputButtonSelect from '@/components/InputButtonSelect'
    import InputSelect from '@/components/InputSelect'
    import WineListItem from '@/components/wine-mastery/WineListItem'
    import HeaderBar from '@/components/HeaderBar'
    import eventBus from '@/utils/event-bus'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            InputButtonSelect,
            InputSelect,
            WineListItem,
            HeaderBar,
            Disclaimer,
            EnjoyResponsibly
        },
        data() {
            return {
                fetchingWines: false
            }
        },
        computed: {
            filters() {
                return this.$store.getters.wineFilters
            },
            wines() {
                return this.$store.getters.wines
            }
        },
        mounted() {
            this.$store.commit('setWineSearchQuery', '')
            eventBus.emit('clearSearch')
            if (this.wines.length === 0) {
                this.fetchWines()
            }
            this.$store.dispatch('fetchWineFilters')
        },
        methods: {
            async changeSelectedFilter(filterId, optionId) {
                await this.$store.commit('setWineSelectedFilter', {
                    id: filterId,
                    selectedOption: optionId
                })
                this.$store.commit('setWinesPage', 0)
                this.fetchWines()
            },

            fetchWines() {
                this.fetchingWines = true
                this.$store.dispatch(
                    'fetchWines',
                    this.$store.getters.wineSelectedFilters
                ).then(() => {
                    this.fetchingWines = false
                })
            },

            viewWine(wine) {
                this.$router.push({
                    path: `/wine-mastery/wines/${wine.id}`
                })
            },

            scrollEvent(e) {
                const element = e.target
                const maxScroll = element.scrollHeight - element.clientHeight
                if (element.scrollTop >= maxScroll - 700 && !this.fetchingWines) {
                    this.fetchWines()
                }
            },
            clearFilters() {
                this.$store.dispatch('clearFilters').then(() => {
                    this.$store.commit('setWinesPage', 0)
                    this.fetchWines()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.list-background {
  position: relative;
  background: url('~@/assets/common/background_image.png');
  width: 100%;

}

.filter-header {
  font-family: CFABold;
  font-size: 3rem;

}

.clear-filters {
  font-family: CFABold;
  padding: 7px 10px;
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: 10px;
}

.close-icon {
  color: #ac7f5e;
}

.filters-container {
  background: #7f2f2d;
  color: #e8e5e4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

.filters-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.filters-bottom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 760px) {
  .filters-bottom-container {
    flex-direction: column;
  }
}

.single-filter-container {
  max-width: 500px;
  width: 46vw;
  align-self: flex-start;
}

.filter-name-text {
  color: #ac7f5e;
  font-family: CFABold;
}

.wines-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 50px;
  height: 83vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  align-content: flex-start;
}

.filter-expand-button {
  color: #ac7f5e;
  font-family: CFABold;
  background: #7f2f2d;
  border: 2px solid #ac7f5e;
  padding: 15px 0px;
  cursor: pointer;
  max-width: 210px;
  width: 100%;
  display: inline-block;
  margin: 4px 5px;
}

</style>
