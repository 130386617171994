<template>
    <div>
        <div class="flex-container">
            <input
                ref="search"
                :value="searchText"
                type="text"
                class="search-input"
                :placeholder="$t('HeaderBar.searchInputPlaceholder')"
                readonly
                @focus="focusEvent()"
            >
            <img :src="require('@/assets/common/searchicon_image.png')">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            focus: {
                type: Boolean,
                required: false,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                input: ''
            }
        },
        computed: {
            searchText() {
                return this.$store.getters.spiritSearchQuery
            }
        },
        watch: {
            searchText() {
                const SpiritSearchRoute = 'SpiritSearchRoute'
                if (this.searchText.length > 2 && this.$router.currentRoute.name !== SpiritSearchRoute) {
                    if (this.$router.currentRoute.name === 'SpiritMasterySpiritRoute') {
                        this.$router.go(-1)
                    } else {
                        this.$router.push({
                            name: SpiritSearchRoute
                        })
                    }
                }
            }
        },
        methods: {
            focusEvent() {
                this.$store.dispatch('showKeyboard')
            }
        }
    }
</script>

<style lang="scss" scoped>
.flex-container {
    display: flex;
    background: #7e2d2b;
    border: 4px solid;
    border-color: #b17f47;
    border-radius: 10px;
}

img {
    height: 51px;
    align-self: center;
    margin-right: 26px;
}

.search-input {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1.7rem;
  font-weight: 400;
  width: 250px;
  height: 90px;
  outline: none;
  background: transparent;
  color: #fff;
  border: none;
}
</style>
