<template>
    <div>
        <span
            v-for="option in options"
            :key="option.id"
            :class="['select-button', {'selected': option.id === selectedId}]"
            @click="select(option.id)"
        >
            {{ option.name }}
        </span>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            options: {
                type: Array,
                required: true
            },
            selected: {
                type: [Number, Boolean],
                required: false,
                default() {
                    return null
                }
            }
        },
        data() {
            return {
                selectedId: -1
            }
        },
        watch: {
        },
        created() {
            this.selectedId = this.selected
        },
        methods: {
            select(optionId) {
                if (this.selectedId === optionId) {
                    this.selectedId = null
                } else {
                    this.selectedId = optionId
                }
                this.$emit('change', this.selectedId)
            }
        }
    }
</script>

<style lang="scss" scoped>

.select-button {
  border: 2px solid #ac7f5e;
  padding: 15px 0px;
  color: #edede3;
  font-size: clamp(12, 1.8vw, 20px);
  font-family: 'CFABold';
  cursor: pointer;
  max-width: 130px;
  width: 12vw;
  display: inline-block;
  text-align: center;
  margin: 4px 5px;
}

.select-button.selected {
  border: 2px solid #edede3;
  background: #edede3;
  color: #5f4e33;
}
</style>
