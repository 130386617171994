<template>
    <div class="disclaimer-text" :style="{background: backgroundColor}">
        <img :src="require('@/assets/common/disclaimer_icon.png')" alt="">
        <div>
            {{ $t('Disclaimer.disclaimerText') }}
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            backgroundColor: {
                type: String,
                required: false,
                default: 'none'
            }
        }
    }
</script>
<style scoped>
.disclaimer-text {
    position: fixed;
    bottom: 0rem;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 12rem .5rem 2rem;
    color: #595a5b;
}
.disclaimer-text img {
    height: 20px;
    margin-right: 1rem;
}
</style>
