<template>
    <div class="wine-roads-background">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <div v-if="rootWineRegions" class="map-container">
            <img :src="require('@/assets/wine-roads/world-map.png')" alt="map" class="map">
            <router-link
                v-for="region of rootWineRegions"
                :key="region.id"
                :to="routerTo(region)"
            >
                <img
                    :src="require('@/assets/wine-roads/map-pin.png')"
                    class="map-pin"
                    :style="{left:pinLeftPercent(region) + '%', bottom:pinBottomPercent(region) + '%'}"
                    :title="region.name"
                    alt="pin"
                >
            </router-link>
        </div>
        <h1 class="title">
            {{ $t('WineRoadsRoute.title[0]') }}<br>{{ $t('WineRoadsRoute.title[1]') }}
        </h1>
        <region-carousel
            v-if="rootWineRegions"
            class="region-carousel"
            :regions="rootWineRegions"
            :bucket-size="4"
        />
        <p class="wine-carousel-title">
            {{ $t('WineRoadsRoute.wineCarouselTitle[0]') }}<br>{{ $t('WineRoadsRoute.wineCarouselTitle[1]') }}
        </p>
        <wine-carousel
            :wines="recommendedWines"
            route-prefix="/wine-roads"
        />
        <div class="wine-roads-container">
            <router-link v-slot="{ navigate }" to="/home" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/sommelier/wine-food-combinations" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.abSommelier')" :text="$t('WineRoadsRoute.combineFoodWithWine')" theme="red" />
                </div>
            </router-link>
            <router-link v-slot="{ navigate }" to="/wine-mastery" custom>
                <div
                    role="link"
                    class="footer-tile"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    <footer-tile :title="$t('WineRoadsRoute.wineMastery')" :text="$t('WineRoadsRoute.chοoseWine')" theme="yellow" />
                </div>
            </router-link>
        </div>
        <enjoy-responsibly :bottom="'20rem'" />
        <disclaimer />
    </div>
</template>
<script>
    import wineRegionService from '@/services/wine-region-service'
    import FooterTile from '@/components/FooterTile'
    import wineService from '@/services/wine-service'
    import RegionCarousel from '@/components/wine-roads/RegionCarousel'
    import Breadcrumb from '@/components/Breadcrumb'
    import WineCarousel from '@/components/WineCarousel'
    import randomizeItems from '@/utils/randomize-items'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, RegionCarousel, WineCarousel, FooterTile, Disclaimer, EnjoyResponsibly },
        data() {
            return {
                rootWineRegions: [],
                imageMinLongitude: -154,
                imageMaxLongitude: 180,
                imageMinLatitude: -58,
                imageMaxLatitude: 120,
                recommendedWines: [],
                path: [{ name: 'Δρόμοι του Κρασιού', path: '/wine-roads' }]
            }
        },
        created() {
            wineRegionService.getRootWineRegions().then((res) => {
                this.rootWineRegions = res.content
            })
            this.fetchRecommendedWines()
        },
        methods: {
            routerTo(region) {
                return `/wine-roads/${region.step}/${region.id}`
            },
            pinLeftPercent(region) {
                return (region.longitude - this.imageMinLongitude) / (this.imageMaxLongitude - this.imageMinLongitude) * 100
            },
            pinBottomPercent(region) {
                return (region.latitude - this.imageMinLatitude) / (this.imageMaxLatitude - this.imageMinLatitude) * 100
            },
            async fetchRecommendedWines() {
                const filterObject = {
                    published: true,
                    swodOnly: false,
                    wineRegionNot: null
                }
                this.recommendedWines = randomizeItems((await wineService.getAll({ filter: filterObject, range: [0, 100], applyClustering: false })).content)
            }
        }
    }
</script>
<style scoped>
.wine-roads-background {
    background: url('~@/assets/wine-roads/step0-bg.png');
    background-size: cover;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.map-container {
    width: 100%;
    max-width: 1026px;
    margin: 2rem auto 0;
    position: relative;
}
.map {
    max-width: 100%;
    height: auto;
}
.map-pin {
    position: absolute;
    max-width: 1.5rem;
    filter: drop-shadow(2.194px 2.046px 3px rgba(27,27,27,0.67));
}

.wine-roads-container {
    display: flex;
    height: 10vh;
}
.footer-tile {
    height: 100%;
    width: 100%;
}
.title {
    height: 104px;
    margin: 1rem 0 0 2.5rem;
    text-align: center;
    font-size: 3.5rem;
    letter-spacing: -1px;
    line-height: 67px;
    color: #d1a679;
    font-family: CFABold;
}

.region-carousel {
    margin-top: 5rem;
}

.wine-carousel-title {
    margin-top: 1rem;
    text-align: center;
    font-family: CFALight;
    font-weight: 600;
    height: 81px;
    font-size: 1.75rem;
    letter-spacing: -1px;
    line-height: 45px;
    color: #2e2d2d;
    text-align: center;
}

@media (min-width: 1080px) and ( orientation: landscape) {
    .wine-roads-container {
        height: 100%;
    }
}

</style>
