import axios from 'axios'
const API_URL = process.env.VUE_APP_HOST_DOMAIN + process.env.VUE_APP_BASE_API

export default {
    post(urlPath, data, cancelToken, headers) {
        return this.request(urlPath, headers, 'POST', data, cancelToken)
    },
    get(urlPath, cancelToken) {
        return this.request(urlPath, null, 'GET', null, cancelToken)
    },
    request(urlPath, headers, method, data, cancelToken) {
        method = method || 'POST'
        data = data || {}
        headers = {
            'Content-Type': 'application/json; charset=utf-8',
            'Kiosk-Token': '253553ab-2ba1-4b52-9060-96e40f7a63e4',
            credentials: 'include',
            ...headers
        }

        if (localStorage.getItem('kioskId') !== null) {
            headers['Kiosk-Id'] = localStorage.getItem('kioskId')
        } else {
            headers['Kiosk-Id'] = '9999'
        }

        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + urlPath,
                data: data,
                method: method,
                headers: headers,
                cancelToken: cancelToken
            })
                .then((response) => {
                    const contetType = response.headers['content-type']
                    if (contetType && contetType.indexOf('application/json') > -1) {
                        return response.data
                    } else {
                        resolve()
                    }
                })
                .then((data) => {
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
