<template>
    <div v-if="spirit" class="pr-3 container">
        <div class="mb-5">
            <div class="producer-text">- {{ spirit.distillingCompany || '' }} -</div>
            <div class="name-text">{{ spirit.commercialName || '' }}</div>
            <div class="volume-text">{{ spirit.category ? spirit.category.name : '' }} {{ spirit.bottleVolume ? spirit.bottleVolume.volume : '' }}</div>
        </div>
        <div class="spirit-bordered-details-container">
            <div class="details-text-container">
                <div v-if="spirit.origin" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.spiritOrigin') }}</div>
                        <div class="details-common-text"> {{ spirit.origin.name || '-' }}</div>
                    </div>
                </div>
                <div v-if="spirit.country" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.country') }}</div>
                        <div class="details-common-text"> {{ spirit.country }}</div>
                    </div>
                </div>
                <div v-if="spirit.location" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/wine-mastery/flag.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.location') }}</div>
                        <div class="details-common-text"> {{ spirit.location }}</div>
                    </div>
                </div>
                <div v-if="spirit.limitedEdition" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.limitedEdition') }}</div>
                        <div class="details-common-text"> {{ spirit.limitedEdition.name }}</div>
                    </div>
                </div>
                <div v-if="spirit.servingSuggestion" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/special_info.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.servingSuggestion') }}</div>
                        <div class="details-common-text"> {{ spirit.servingSuggestion.name }}</div>
                    </div>
                </div>
                <div class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.aged') }}</div>
                        <div class="details-common-text"> {{ spirit.aged === true ? 'Ναι' : 'Όχι' }}</div>
                    </div>
                </div>
                <div v-if="spirit.abv" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.abv') }}</div>
                        <div class="details-common-text"> {{ spirit.abv }} </div>
                    </div>
                </div>
                <div v-if="spirit.foodCombinations.length>0" class="details-wrapper food-combinations">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/special_info.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.foodCombinations') }}</div>
                        <div class="details-common-text">
                            <span
                                v-for="(combination, index) in spirit.foodCombinations"
                                :key="combination.name"
                                class="combination"
                            >
                                {{ combination.name || '-' }}{{ index === spirit.foodCombinations.length - 1 ? '' : ',' }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="spirit.servingGlassSuggestion" class="details-wrapper">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/glass.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.servingGlassSuggestion') }}</div>
                        <div class="details-common-text"> {{ spirit.servingGlassSuggestion }} </div>
                    </div>
                </div>
                <div v-if="spirit.color" class="details-wrapper" :class="{'long-text': spirit.color.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/glass.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.color') }}</div>
                        <div class="details-common-text"> {{ spirit.color }} </div>
                    </div>
                </div>
                <div v-if="spirit.smellInfo" class="details-wrapper" :class="{'long-text': spirit.smellInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.smell') }}</div>
                        <div class="details-common-text">{{ spirit.smellInfo }}</div>
                    </div>
                </div>
                <div v-if="spirit.tasteInfo" class="details-wrapper" :class="{'long-text': spirit.tasteInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/glass.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.taste') }}</div>
                        <div class="details-common-text">{{ spirit.tasteInfo }}</div>
                    </div>
                </div>
                <div v-if="spirit.agingInfo" class="details-wrapper" :class="{'long-text': spirit.agingInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.agingInfo') }}</div>
                        <div class="details-common-text"> {{ spirit.agingInfo }} </div>
                    </div>
                </div>
                <div v-if="spirit.distillationInfo" class="details-wrapper" :class="{'long-text': spirit.distillationInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.distillationInfo') }}</div>
                        <div class="details-common-text"> {{ spirit.distillationInfo }} </div>
                    </div>
                </div>
                <div v-if="spirit.rawMaterials" class="details-wrapper" :class="{'long-text': spirit.rawMaterials.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/bottle.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.rawMaterials') }}</div>
                        <div class="details-common-text"> {{ spirit.rawMaterials }} </div>
                    </div>
                </div>
                <div v-if="spirit.noteworthyInfo" class="details-wrapper" :class="{'long-text': spirit.noteworthyInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.distillationInfo') }}</div>
                        <div class="details-common-text"> {{ spirit.noteworthyInfo }} </div>
                    </div>
                </div>
                <div v-if="spirit.extraSpecialInfo" class="details-wrapper" :class="{'long-text': spirit.extraSpecialInfo.length>50}">
                    <div class="img-container"><img :src="require('@/assets/spirit-mastery/barrel.png')"></div>
                    <div class="fix-width">
                        <div class="details-header-text">{{ $t('SpiritDetailsTable.extraSpecialInfo') }}</div>
                        <div class="details-common-text"> {{ spirit.extraSpecialInfo }} </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="disclaimer-header-text">{{ $t('SpiritDetailsTable.disclaimer') }}</div>
                        <div class="disclaimer-common-text">{{ $t('SpiritDetailsTable.disclaimerInfo') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            spirit: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
.container {
    width: 100%;
}
.details-text-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    height: 25rem;
    padding: 1rem;
}

.spirit-bordered-details-container{
    height: 100%;
    width: 100%;
    padding: .5rem;
    border: 1px solid #dfdfdc;
    color: black;
}

.details-wrapper{
    display: flex;
    flex-direction: row;
    justify-self: start;
    flex: 50%;
    min-height: 5rem;
    padding: .5rem;
}
.long-text {
    flex: 100%;
}

.img-container{
    display: flex;
    min-width: 52px;
    height: 51px;
    justify-content: center;
}
.img-container img {
    height: 100%;
}

.fix-width {
    display: flex;
    flex-direction: column;
    padding-left: .7rem;
}

.details-header-text{
    color: #ac3846;
    font-size: clamp(15px,1.6vw,18px);
    font-family: CFALight;
    font-weight: 700;
}

.disclaimer-header-text{
    color: #ac3846;
    font-size: clamp(10px,1.6vw,12px);
    font-family: CFALight;
    font-weight: 700;
    padding-top: .5rem;
}

.details-common-text{
    color: #59595b;
    font-size: clamp(15px,1.6vw,18px);
    font-family: CFALight;
    font-weight: 700;
}

.disclaimer-common-text{
    color: #59595b;
    font-size: clamp(8px,1.6vw,10px);
    font-family: CFALight;
    font-weight: 700;
}

.producer-text{
    color: #ac7f5e;
    font-size: clamp(15px,3vw,25px);
    font-family: CFABold;
}

.name-text{
    color: #ac3846;
    font-size: clamp(30px,5.7vw,50px);
    font-family: CFABold;
    line-height: normal;
    max-width: 29rem;
}

.volume-text{
    color: #808181;
    font-size: clamp(15px,3vw,20px);
    font-family: CFABold;
}

</style>
