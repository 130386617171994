<template>
    <div v-if="wineRegion" class="background">
        <div class="blurred-background">
            <breadcrumb :path="path">
                <img :src="require('@/assets/common/home-button.png')">
            </breadcrumb>
            <wine-region-info class="mx-4 mt-3" :wine-region="wineRegion" />
        </div>
        <div class="region-trivia-container">
            <wine-trivia-card
                :trivia-data="wineRegion.extraRegionInfoTop"
                :trivia-text="wineRegion.extraRegionInfoBottom"
            >
                <img src="@/assets/wine-roads/grapes.png" alt="greek-varieties">
            </wine-trivia-card>
            <wine-trivia-card
                :trivia-data="wines.length.toString()"
                :trivia-text="wines.length !== 1 ? $t('WineRoadsStep2.wineEtiquetes') : $t('WineRoadsStep2.wineEtiquete')"
            >
                <img src="@/assets/wine-roads/wine-count.png" alt="wine-count">
            </wine-trivia-card>
            <wine-trivia-card
                :trivia-data="wineRegion.extraRegionInfoTopTwo"
                :trivia-text="wineRegion.extraRegionInfoBottomTwo"
            >
                <img src="@/assets/wine-roads/winefields.png" alt="winefield">
            </wine-trivia-card>
        </div>
        <div
            v-if="wines"
            class="region-wines-container"
        >
            <h1 v-if="wineRegion" class="title">{{ $t('WineRoadsStep3.wines') }} {{ wineRegion.nameGenitive || 'της περιοχής' }}</h1>
            <div class="total-wines">
                - {{ wines.length }} {{ wines.length !== 1 ? $t('WineRoadsStep3.labels') : $t('WineRoadsStep3.label') }} -
            </div>
        </div>
        <wine-carousel
            :wines="wines"
            route-prefix="/wine-roads"
            class="wine-carousel"
        />
        <router-link
            :to="`/wine-roads/3/${wineRegion.id}/all-wines`"
        >
            <div class="all-wines-btn">
                <span>
                    {{ $t('WineRoadsStep3.allWines') }}
                </span>
            </div>
        </router-link>
        <enjoy-responsibly />
        <disclaimer />
    </div>
</template>
<script>
    import wineRegionService from '@/services/wine-region-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import WineRegionInfo from '@/components/wine-roads/WineRegionInfo'
    import WineCarousel from '@/components/WineCarousel'
    import WineTriviaCard from '@/components/wine-roads/WineTriviaCard'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, WineRegionInfo, WineCarousel, WineTriviaCard, Disclaimer, EnjoyResponsibly },
        data() {
            return {
                wineRegion: null,
                path: [],
                rootPath: [{ name: 'Δρόμοι του Κρασιού', path: '/wine-roads' }],
                wines: []
            }
        },
        async created() {
            const wineRegion = await wineRegionService.getWineRegionById(this.$route.params.regionId)
            this.wineRegion = wineRegion

            const path = await wineRegionService.getBreadcrumbPath(this.rootPath, this.wineRegion)
            this.path = path
            wineRegionService.getWinesByRegionId(this.$route.params.regionId)
                .then((resp) => {
                    this.wines = resp.content
                })
        }
    }
</script>
<style scoped>
.background {
    background: url('~@/assets/wine-roads/step3-background.png');
    background-repeat: no-repeat;
}
.blurred-background {
    background: url('~@/assets/wine-roads/step3-background.jpg');
    background-repeat: no-repeat;
}

.region-trivia-container {
    background-color: #882c2e;
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
}
.region-wines-container {
    height: 23.438rem;
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    font-family: 'CFABold';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.total-wines {
    font-family: 'CFALight';
    font-size: 1.5rem;
}
.title {
    font-size: 3.8rem;
}
.wine-carousel {
    margin-top: -10%;
}
.all-wines-btn {
    max-width: max-content;
    margin: 1.5rem auto;
    background-color: #882c2e;
    padding: 1.2rem 4.8rem;
    border-radius: 12px;
    filter: drop-shadow(3.625px 1.69px 3.5px rgba(27,27,27,0.34));
    cursor: pointer;
}
.all-wines-btn span {
    color: #fff;
    font-size: 1.5rem;
    font-family: 'CFABold';
}

</style>
