<template>
    <div style="position: relative;" class="select-container" tabindex="0" @click="toggleExpanded" @blur="close">
        <div ref="selectInput" :class="['select-input', {'expanded': expanded}, {'selected': selectedId !== 0}]">
            <transition name="fade" mode="out-in">
                <span class="pl-4">
                    {{ selectedOption.name || selectedOption.volume }}
                </span>
            </transition>
            <span class="drop-down">
                <transition name="fade" mode="out-in">
                    <font-awesome-icon
                        :icon="!expanded ? 'angle-down' : 'times'"
                    />
                </transition>
            </span>
            <transition name="slide">
                <div v-show="expanded" ref="options" class="options-container">
                    <ul>
                        <li
                            :class="[{'selected': selectedId === 0}]"
                            @click="select({name: 'Όλες', id: 0})"
                        >Όλες</li>
                        <li
                            v-for="option in options"
                            :key="option.id"
                            :class="[{'selected': option.id == selectedId}]"
                            @click="select(option)"
                        >
                            {{ option.name || option.volume }}
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                required: true
            },
            selected: {
                type: Number,
                required: false,
                default() {
                    return null
                }
            }
        },
        data() {
            return {
                selectedId: -1,
                expanded: false
            }
        },
        computed: {
            selectedOption() {
                let selected = {
                    name: 'Όλες',
                    id: 0
                }
                this.options.forEach(element => {
                    if (element.id === this.selectedId) {
                        selected = element
                    }
                })
                return selected
            }
        },
        created() {
            this.selectedId = this.selected
        },
        mounted() {
        },
        methods: {
            select(option) {
                this.selectedId = option.id
                this.$emit('change', this.selectedId)
            },
            toggleExpanded() {
                this.expanded = !this.expanded
            },
            close() {
                this.expanded = false
            }
        }
    }
</script>

<style lang="scss" scoped>

.select-input {
  border: 2px solid #ac7f5e;
  padding: 15px 0px;
  cursor: pointer;
  max-width: 410px;
  width: 100%;
  display: inline-block;
  margin: 4px 5px;
  position: relative;
}

.select-input.selected {
  color: #edede3;
  font-size: clamp(12, 1.8vw, 20px);
  font-family: CFABold;
}

.drop-down {
  position: absolute;
  right: 14px;
}

.options-container ul{
  padding: 0px;
  list-style-type: none;
  margin-bottom: 0px;
  max-height: 400px;
  overflow-y: auto;
  border: 2px solid #ac7f5e;
  border-top: none;
  z-index: 2;
  position: absolute;
  top: 3.5rem;
  left: 0;
  right: 0;
}
.options-container li{
  width: 100%;
  border-top: 1px solid #ac7f5e;
  padding: 15px 20px;
  background: #edede3;
  color: #ac7f5e;
  font-size: clamp(12, 1.8vw, 20px);
  font-family: 'CFABold';
}

.options-container li.selected{
  background: #edede3;
  color: black;
}

.options-container {
  max-height: 400px;
  overflow: hidden;
}

.slide-enter-active, .slide-leave-active {
  transition: max-height 0.25s;
}
.slide-enter , .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0px;
}

.fade-enter-active, .fade-leave-active {
  transition: display 0.13s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  display: hidden;
}

</style>
