<template>
    <div class="tooltip-container">
        <div class="bubble-photo">
            <img :src="require('@/assets/tips/bubble-photo.png')" alt="bubble photo">
        </div>
        <div
            class="tip-tooltip"
            :class="{'show-chat-bubble': showChat}"
        >
            <div class="text-bubble">
                <div class="bubble-content" @click="showTip">
                    {{ $t('Tips.didYouKnow') }} <img :src="require('@/assets/tips/chevron-right.png')" alt="click here">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dragging: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            showChat() {
                return !this.dragging
            }
        },
        methods: {
            showTip() {
                this.$store.commit('setTipModalOpen', true)
            }
        }
    }
</script>

<style scoped>
.tooltip-container {
    position: absolute;
    top: 25%;
    right: 2%;
    width: 320px;
    height: 200px;
}

.tip-tooltip {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.75s ease;
}

.show-chat-bubble {
    opacity: 1;
    visibility: visible;
}

.text-bubble {
    cursor: pointer;
    background: url('~@/assets/tips/bubble.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 240px;
    height: 100px;
    display: grid;
    place-items: center;
}

.bubble-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'CFABold';
    color: #fff;
    font-style: italic;
    font-size: 1.375rem;
    font-family: 'CFABold';
    transform: translateY(-20%);
}

.bubble-photo {
    position: absolute;
    top: 30%;
    right: 0;
}
</style>
