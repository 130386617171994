<template>
    <div class="enjoy-responsibly" :style="{bottom: bottom, right: right}">
        {{ $t('EnjoyResponsibly.enjoyResponsibly') }}
    </div>
</template>
<script>
    export default {
        props: {
            bottom: {
                type: String,
                required: false,
                default: '8rem'
            },
            right: {
                type: String,
                required: false,
                default: '-4rem'
            }
        }
    }
</script>
<style scoped>
.enjoy-responsibly {
    position: fixed;
    color: #a7a7a6;
    font-size: 1rem;
    font-family: CFALight;
    font-weight: 600;
    transform: rotate(-90deg);
}
</style>
