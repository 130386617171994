<template>
    <div class="food-combinations-section">
        <div class="header-background" />
        <breadcrumb :path="path" :is-dark="true" class="breadcrumb-position">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <div class="food-suggestions">
            <h1 class="mt-3">{{ $t('WineFoodCombinationRoute.header')[0] }}<span> {{ $t('WineFoodCombinationRoute.header')[1] }}</span> {{ $t('WineFoodCombinationRoute.header')[2] }}</h1>
            <div class="my-5">
                {{ $t('WineFoodCombinationRoute.description')[0] }} <span class="sommelier">{{ $t('WineFoodCombinationRoute.description')[1] }}</span>
                <div>
                    {{ $t('WineFoodCombinationRoute.description')[2] }}
                </div>
            </div>
            <div class="food-suggestions-container mt-5 pt-4">
                <div
                    v-for="foodCombination in rootFoodCombinations"
                    :key="foodCombination.id"
                    class="food-item"
                    @click="navigateToFoodCombination(foodCombination.id)"
                >
                    <img v-if="foodCombination.platePhoto" :src="config.host + '/' + foodCombination.platePhoto.relativePath + '/' + foodCombination.platePhoto.fileName" class="plate-img">
                    <img v-else :src="require('@/assets/sommelier/meat_food.png')" class="plate-img">
                    <div>{{ foodCombination.name }}</div>
                </div>
            </div>
        </div>
        <enjoy-responsibly />
    </div>
</template>
<script>
    import foodComboService from '@/services/food-combo-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import config from '@/mixins/config'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, EnjoyResponsibly },
        mixins: [config],
        data() {
            return {
                rootFoodCombinations: [],
                path: [
                    { name: 'AB Sommelier', path: '/sommelier' },
                    { name: 'Αρμονία Κρασιού & Φαγητού', path: '/sommelier/wine-food-combinations' }
                ]
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                foodComboService.getRootFoodCombinations().then((res) => {
                    this.rootFoodCombinations = res.content
                })
            },
            navigateToFoodCombination(foodCombinationId) {
                this.$router.push({
                    path: `/sommelier/wine-food-combinations/1/${foodCombinationId}`
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.header-background {
    background: url('~@/assets/sommelier/foodsuggestions_header_background.png');
    width: 100%;
    height: 58.25rem;
}

.breadcrumb-position{
    position: absolute;
    top: 0;
}

.food-combinations-section {
    background: #f1f1ef;
    height: 100%;
}
.food-suggestions {
    padding: 0 5%;
    color: #7f2f2d;
    font-family: 'CFALight';
    text-align: center;
}
.food-suggestions h1 {
    font-size: 3.75rem;
    font-family: 'CFABold';

    span {
        color: #c14744;
    }
}

.food-suggestions div {
    font-size: 1.25rem;
}

.food-suggestions-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 5rem;
    justify-content: space-evenly;
    align-items: flex-start;
}

.food-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.food-item div {
    font-size: 1.375rem;
    font-family: 'CFABold';
    width: 12rem;
}
.sommelier {
    font-weight: 700;
}
.plate-img {
    width: 160px;
    filter: drop-shadow(5px 0px 6px rgba(78,76,73,0.4));
}
</style>
