import restAPI from '@/utils/rest-api'

export default {
    getRootWineRegions() {
        return restAPI.get(encodeURI('/wine-regions/roots'))
    },
    getWineRegionById(id) {
        return restAPI.get(encodeURI(`/wine-regions/${id}`))
    },
    getWinesByRegionId(regionId) {
        return restAPI.get(`/wine-regions/${regionId}/wines`)
    },
    getBreadcrumbPath(rootPath, currentWineRegion) {
        let path = []
        if (!currentWineRegion) {
            path = [...rootPath]
            return Promise.resolve(path)
        }

        while (currentWineRegion) {
            currentWineRegion.path = `/wine-roads/${currentWineRegion.step}/${currentWineRegion.id}`
            path.unshift(currentWineRegion)
            currentWineRegion = currentWineRegion.parent
        }
        path = [...rootPath, ...path]
        return Promise.resolve(path)
    }
}
