<template>
    <div class="spirit-info-background">
        <header-bar target-route="SpiritScanRoute" />
        <div v-if="spirit" class="main-details-container">
            <div class="spirit-details-container">
                <product-gallery :images="spirit.avatarPhotos" bottle-type="spirit" class="gallery" />
                <spirit-details-table :spirit="spirit" class="spirit-details" />
            </div>
            <div class="spirit-description-container">
                <p>
                    {{ spirit.miniCv | nullCheck }}
                </p>
            </div>
            <p class="carousel-title">- {{ $t('SpiritMasterySpiritRoute.recommendedSpirits') }} -</p>
            <spirit-carousel
                :spirits="recommendedSpirits"
                route-prefix="/spirit-mastery"
            />
        </div>
        <enjoy-responsibly />
        <disclaimer :background-color="'#f4ead8'" />
    </div>
</template>

<script>
    import spiritService from '@/services/spirit-service'
    import statisticsService from '@/services/statistics-service'
    import HeaderBar from '@/components/HeaderBar'
    import config from '@/mixins/config'
    import SpiritDetailsTable from '@/components/spirit-mastery/SpiritDetailsTable'
    import ProductGallery from '@/components/ProductGallery'
    import randomizeItems from '@/utils/randomize-items'
    import SpiritCarousel from '@/components/spirit-mastery/SpiritCarousel'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: {
            HeaderBar,
            SpiritDetailsTable,
            ProductGallery,
            SpiritCarousel,
            Disclaimer,
            EnjoyResponsibly
        },
        mixins: [config],
        beforeRouteUpdate(to, from, next) {
            this.init(to.params.id)

            next()
        },
        data() {
            return {
                spirit: null,
                recommendedSpirits: []
            }
        },
        async mounted() {
            this.init(this.$route.params.id)
        },
        methods: {
            async init(id) {
                this.spirit = await spiritService.get(id)
                this.fetchRecommendedSpirits()

                if (this.$route.query.fromScan) {
                    statisticsService.spiritScanned(this.spirit.id)
                } else {
                    statisticsService.spiritVisited(this.spirit.id)
                }
            },
            async fetchRecommendedSpirits() {
                this.recommendedSpirits = []
                if (this.spirit.category === null) {
                    return
                }
                const filterObject = {
                    idNot: this.spirit.id,
                    category: this.spirit.category.id,
                    published: true
                }
                const fetchedSpirits = (await spiritService.getAll({ filter: filterObject, range: [0, 100] })).content

                this.recommendedSpirits = randomizeItems(fetchedSpirits)
            }
        }
    }
</script>

<style scoped>
.spirit-info-background {
    background: url('~@/assets/spirit-mastery/background_spirit_info.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.main-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.spirit-details-container {
  display: flex;
  margin-bottom: 1rem;
}

.spirit-details {
    align-self: center;
    width: 70%;
    padding: 0 5%;
}
.gallery {
    max-width: 30%;
}
.spirit-description-container p{
    columns: 2 240px;
    width: 90%;
    margin: 0 auto;
    max-height: 35rem;
}
.spirit-description-container {
    height: 17rem;
    margin: 2rem 1rem;
    overflow: scroll;
}
.carousel-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #ac3846;
}
.spirit-info-background {
    background: url('~@/assets/spirit-mastery/background_spirit_info.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}

.main-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.spirit-details-container {
  display: flex;
  margin-bottom: 1rem;
}

</style>
