<template>
    <div v-if="wine" class="wine-container">
        <img :src="wine | avatarBuilder('wine')" class="wine-img" alt="wine image">
        <div class="wine-info ml-3">
            <span class="wine-title">{{ wine.commercialName | nullCheck }}</span>
            <span
                class="winery"
            >- {{ wine.winery }} -</span>
            <span class="wine-color-text">{{
                wine.wineColor | nullCheck('name')
            }}</span>
            <span v-if="wine.wineBottleVolume">{{
                wine.wineBottleVolume.volume
            }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            wine: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
.wine-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 340px;
  cursor: pointer;
}

.wine-img {
  max-height: 220px;
  height: 100%;
  bottom: 0;
  right: 0px;
  float: right;
  max-width: 100%;
  align-self: center;
}

.wine-info {
  max-width: 250px;
  width: 23vw;
  display: flex;
  flex-direction: column;
}

.wine-title {
  color: #ac3846;
  font-family: CFABold;
  font-size: clamp(15px, 2.4vw, 26px);
  line-height: 35px;
}

.winery {
  color: #ac7f5e;
  font-family: CFALight;
  font-size: clamp(15px, 1.6vw, 18px);
}

.wine-color-text {
  font-family: CFABold;
  color: #59595b;
  font-size: clamp(15px, 1.6vw, 18px);
  margin-top: auto;
}
</style>
