<template>
    <div class="food-combination-container">
        <div class="main-section text-center">
            <div v-if="foodCombination" class="head-section">
                <breadcrumb :path="path" :is-dark="true">
                    <img :src="require('@/assets/common/home-button.png')">
                </breadcrumb>
                <h1 class="mt-4 title" :class="{'long-food-name': foodCombination.name.length>37}">
                    {{ foodCombination | nullCheck('name') }}
                </h1>
                <div class="food-combination-img-wrapper">
                    <img v-if="foodCombination.platePhoto" :src="calculatePlatePhoto" alt="plate-photo">
                    <img v-else src="@/assets/sommelier/chicken_food.png" alt="plate-photo">
                </div>
                <div v-if="foodCombination" class="food-items-container">
                    <div
                        v-for="(food, index) in foodCombination.children"
                        :key="food.id"
                        :class="['food-item', { 'active': index === selectedIndex }]"
                        @click="selectFood(index, food.id)"
                    >
                        {{ food.name }}
                    </div>
                </div>
            </div>
            <div class="wine-pairing-section">
                <img :src="require('@/assets/sommelier/sommelier.png')" class="sommelier">
                <h1 class="mt-2">
                    {{ $t('FoodPairing.goesWith') }}
                </h1>
                <span>- {{ wines.length }} {{ $t('WineFoodCombinationStep1Route.labels') }} -</span>
                <div class="wine-pairing-container">
                    <wine-carousel
                        v-if="wines.length > 0"
                        :bucket-size="6"
                        :wines="wines"
                        route-prefix="/sommelier/wine-food-combinations"
                        carousel-height="52rem"
                    />
                    <div v-else class="no-wines-text">
                        {{ $t('WineFoodCombinationStep1Route.noWines') }}
                    </div>
                </div>
            </div>
        </div>
        <enjoy-responsibly />
        <disclaimer />
    </div>
</template>
<script>
    import foodComboService from '@/services/food-combo-service'
    import Breadcrumb from '@/components/Breadcrumb'
    import WineCarousel from '@/components/WineCarousel'
    import randomizeItems from '@/utils/randomize-items'
    import config from '@/mixins/config'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'
    import Disclaimer from '@/components/Disclaimer'

    export default {
        components: {
            WineCarousel, Breadcrumb, Disclaimer, EnjoyResponsibly
        },
        mixins: [config],
        data() {
            return {
                selectedIndex: 0,
                foodCombination: null,
                path: [],
                wines: [],
                rootPath: [{ name: 'AB Sommelier', path: '/sommelier' }, { name: 'Αρμονία Κρασιού & Φαγητού', path: '/sommelier/wine-food-combinations' }]
            }
        },
        computed: {
            calculatePlatePhoto() {
                return `${this.config.host}/${this.foodCombination.platePhoto.relativePath}/${this.foodCombination.platePhoto.fileName}`
            }
        },
        async created() {
            const foodCombination = await foodComboService.getFoodCombinationById(this.$route.params.foodId)
            this.foodCombination = foodCombination

            let foodId = this.foodCombination.id
            if (foodCombination.children.length > 0) {
                foodId = foodCombination.children[0].id
            }
            this.fetchFoodWines(foodId)

            const path = await foodComboService.getBreadcrumbPath(this.rootPath, this.$route.params.foodId, this.foodCombination)
            this.path = path
        },
        methods: {
            selectFood(index, childFoodId) {
                this.selectedIndex = index
                this.fetchFoodWines(childFoodId)
            },
            async fetchFoodWines(foodId) {
                const wines = await foodComboService.getWinesByFoodId({ foodId })
                this.wines = randomizeItems(wines.content)
            }
        }
    }
</script>

<style scoped>
.main-section {
    color: #fff;
    font-family: 'CFALight';
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}
.main-section h1 {
    font-family: 'CFABold';
    font-size: 4.5rem;
}
.main-section span {
    font-size: 1.25rem;
}
.food-combination-container {
    width: 100%;
    height: 100%;
}
.head-section {
    background: url('~@/assets/sommelier/foodcombination_head_background.jpg');
    padding-bottom: 12rem;
    height: 47%;
}
.title {
    padding: 0 5rem;
}
.long-food-name {
    padding: 0 1rem;
}
.sommelier {
    margin-top: -5rem;
}

.wine-pairing-section {
    background: url('~@/assets/sommelier/foodcombination_main_background.png') no-repeat #f2f2f0;
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    color: #7f2f2d;
    font-family: 'CFALight';
    flex: 1;
}
.wine-pairing-section h1 {
    font-size: 1.25rem;
    font-family: 'CFABold';
}
.food-combination-img-wrapper {
    margin-top: 5%;
}
.food-combination-img-wrapper img {
    width: 27rem;
    height: auto;
}
.food-items-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 5%;
}
.food-item {
    font-size: 1.4rem;
    font-family: 'CFABold';
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    line-height: 1.8rem;
    background: #7f2f2d;
    color: #cfa87d;
    padding: .5rem 1rem;
    width: 18rem;
    margin-bottom: 1.5%;
    border: 2px solid #cfa87d;
}
.food-item.active {
    background: #cfa87d;
    color: #7f2f2d;
}
.wine-pairing-container {
    height: 52rem;
}
.no-wines-text{
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 2rem;
    font-size: 2rem;
    font-family: 'CFABold';
}

</style>
