<template>
    <div v-if="wineRegion" class="wine-roads-step-1-bg">
        <breadcrumb :path="path">
            <img :src="require('@/assets/common/home-button.png')">
        </breadcrumb>
        <div
            v-if="wineRegion.children"
            class="map-container"
        >
            <img :src="imgPath(wineRegion)" alt="map" class="map">
            <router-link v-for="child of wineRegion.children" :key="child.id" :to="routerTo(child)">
                <img
                    :src="require('@/assets/wine-roads/map-pin.png')"
                    class="map-pin"
                    :style="{left:pinLeftPercent(child) + '%', bottom:pinBottomPercent(child) + '%'}"
                    :title="child.name"
                    alt="pin"
                >
            </router-link>
        </div>
        <h1 class="title">{{ wineRegion.name }}</h1>
        <region-carousel
            v-if="wineRegion.children"
            class="region-carousel"
            :regions="wineRegion.children"
            :bucket-size="4"
        />
        <p
            v-if="recommendedWines"
            class="wine-carousel-title"
        >
            - {{ recommendedWines.length }} {{ recommendedWines.length !== 1 ? $t('WineRoadsStep1Route.wineEtiquetes') : $t('WineRoadsStep1Route.wineEtiquete') }} -
        </p>
        <wine-carousel
            v-if="recommendedWines"
            :wines="recommendedWines"
            :bucket-size="6"
            carousel-height="47rem"
            route-prefix="/wine-roads"
        />
        <enjoy-responsibly />
        <disclaimer />
    </div>
</template>
<script>
    import wineRegionService from '@/services/wine-region-service'
    import config from '@/mixins/config'
    import RegionCarousel from '@/components/wine-roads/RegionCarousel'
    import WineCarousel from '@/components/WineCarousel'
    import Breadcrumb from '@/components/Breadcrumb'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { Breadcrumb, RegionCarousel, WineCarousel, Disclaimer, EnjoyResponsibly },
        mixins: [config],
        data() {
            return {
                wineRegion: null,
                path: [],
                recommendedWines: [],
                rootPath: [{ name: 'Δρόμοι του Κρασιού', path: '/wine-roads' }]
            }
        },
        async created() {
            const wineRegion = await wineRegionService.getWineRegionById(this.$route.params.regionId)
            this.wineRegion = wineRegion

            const path = await wineRegionService.getBreadcrumbPath(this.rootPath, this.wineRegion)
            this.path = path

            const regionalWines = await wineRegionService.getWinesByRegionId(this.$route.params.regionId)
            this.recommendedWines = regionalWines.content
        },
        methods: {
            routerTo(region) {
                return `/wine-roads/${region.step}/${region.id}`
            },
            imgPath(region) {
                if (!region.photo) return
                return `${this.config.host}/${region.photo.relativePath}/${region.photo.fileName}`
            },
            pinLeftPercent(child) {
                return Math.abs(((child.longitude - this.wineRegion.imageMinLongitude) / (this.wineRegion.imageMaxLongitude - this.wineRegion.imageMinLongitude)) * 100)
            },
            pinBottomPercent(child) {
                return Math.abs(((child.latitude - this.wineRegion.imageMinLatitude) / (this.wineRegion.imageMaxLatitude - this.wineRegion.imageMinLatitude)) * 100)
            }
        }
    }
</script>
<style scoped>
.wine-roads-step-1-bg {
    background: url('~@/assets/wine-roads/step1-bg.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
}
.map-container {
    width: 100%;
    max-width: max-content;
    margin: 0 auto;
    position: relative;
    margin-top: .3rem;
}
.map {
    max-width: 100%;
    height: 40.6rem;
}
.map-pin {
    position: absolute;
    max-width: 2.6rem;
    filter: drop-shadow(2.194px 2.046px 3px rgba(27,27,27,0.67));
}

.title {
    margin-top: 1rem;
    font-size: 4rem;
    letter-spacing: -1px;
    color: #d1a679;
    font-family: CFABold;
    text-align: center;
}
.region-carousel {
    margin-top: 1rem;
}
.wine-carousel-title {
    font-size: 1.25rem;
    color: #7f2f2d;
    text-align: center;
    margin: 1.6rem 0 0.3rem 0;
}

</style>
