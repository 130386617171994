<template>
    <div>
        <div class="list-background">
            <div class="header-bar-container">
                <header-bar target-route="SpiritScanRoute" />
            </div>
            <div>
                <div id="collapse-filters" visible>
                    <div class="filters-container py-3">
                        <div class="filters-top-container mx-3">
                            <h3 class="filter-header">{{ $t('SpiritMasteryRoute.filters') }}</h3>
                            <span
                                v-if="filters.some(filter => filter.selectedId !== 0)"
                                class="clear-filters"
                                @click="clearFilters"
                            >
                                <font-awesome-icon class="close-icon" icon="times" />
                                {{ $t('SpiritMasteryRoute.clearFilters') }}
                            </span>
                        </div>
                        <div class="filters-bottom-container">
                            <div
                                v-for="filter in filters"
                                :key="filter.field + filter.selectedId"
                                class="single-filter-container"
                            >
                                <h5 class="filter-name-text mt-3">
                                    {{ filter.name }}
                                </h5>
                                <div>
                                    <input-button-select
                                        v-if="filter.options.length <= 3 && filter.field !== 'subCategory'"
                                        :options="filter.options"
                                        :selected="filter.selectedId"
                                        @change="(selectedId) => changeSelectedFilter(filter.field, selectedId)"
                                    />
                                    <input-select
                                        v-else
                                        :options="filter.options"
                                        :selected="filter.selectedId"
                                        @change="(selectedId) => changeSelectedFilter(filter.field, selectedId)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="spirit-list"
                        @scroll="scrollEvent"
                    >
                        <div
                            v-for="spirit, index in spirits"
                            :key="index"
                            @click="viewSpirit(spirit)"
                        >
                            <spirit-list-item :spirit="spirit" />
                        </div>
                        <b-col
                            v-if="spirits.length===0"
                            class=" no-products"
                        >
                            {{ $t('SpiritMasteryRoute.productsNotFound[0]') }} <br>{{
                                $t('SpiritMasteryRoute.productsNotFound[1]')
                            }}
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
        <enjoy-responsibly />
        <disclaimer :background-color="'#f4ead8'" />
    </div>
</template>
<script>
    import spiritService from '@/services/spirit-service'
    import randomizeItems from '@/utils/randomize-items'
    import joinWines from '@/utils/join-wines'
    import HeaderBar from '@/components/HeaderBar'
    import InputSelect from '@/components/InputSelect'
    import InputButtonSelect from '@/components/InputButtonSelect'
    import SpiritListItem from '@/components/spirit-mastery/SpiritListItem'
    import eventBus from '@/utils/event-bus'
    import Disclaimer from '@/components/Disclaimer'
    import EnjoyResponsibly from '@/components/EnjoyResponsibly'

    export default {
        components: { HeaderBar, InputSelect, InputButtonSelect, SpiritListItem, Disclaimer, EnjoyResponsibly },
        data() {
            return {
                filters: [],
                spirits: [],
                rootCategories: [],
                cancelToken: {},
                fetchingSpirits: false,
                page: 0
            }
        },
        async mounted() {
            this.$store.commit('setSpiritSearchQuery', '')
            eventBus.emit('clearSearch')
            const spiritCategories = await spiritService.getSpiritRootCategories()
            this.rootCategories = spiritCategories.content
            this.fetchSpiritFilters()
            this.fetchSpirits()
        },
        methods: {
            async fetchSpiritFilters() {
                const filters = []

                filters.push({
                    name: 'ΚΑΤΗΓΟΡΙΑ',
                    field: 'category',
                    selectedId: 0,
                    options: this.rootCategories
                })

                const spiritRegions = await spiritService.getSpiritOrigins()
                filters.push({
                    name: 'ΠΡΟΕΛΕΥΣΗ',
                    field: 'origin',
                    selectedId: 0,
                    options: spiritRegions.content
                })

                filters.push({
                    name: 'ΥΠΟΚΑΤΗΓΟΡΙΑ',
                    field: 'subCategory',
                    selectedId: 0,
                    options: []
                })

                const bottleVolumes = await spiritService.getSpiritBottleVolumes()
                filters.push({
                    name: 'ΣΥΣΚΕΥΑΣΙΑ',
                    field: 'bottleVolume',
                    selectedId: 0,
                    options: bottleVolumes.content
                })
                filters.push({
                    name: 'ΠΑΛΑΙΩΣΗ',
                    field: 'aged',
                    selectedId: 0,
                    options: [
                        { id: true, name: 'Ναι' },
                        { id: false, name: 'Όχι' }
                    ]
                })
                this.filters = filters
            },
            changeSelectedFilter(filterName, selectedId) {
                if (filterName === 'category') {
                    if (selectedId === 0) {
                        this.filters.find(filter => filter.field === 'category').selectedId = selectedId
                        this.filters.find(filter => filter.field === 'subCategory').options = []
                        this.filters.find(filter => filter.field === 'subCategory').selectedId = selectedId
                    } else {
                        const fetchedSubCategories = this.rootCategories.find(rc => rc.id === selectedId).children
                        const sanitizedSubcategories = fetchedSubCategories.map(subCat => {
                            subCat.name = this.extractSubCategoryName(subCat.name)
                            return subCat
                        })

                        this.filters.find(filter => filter.field === 'subCategory').options = sanitizedSubcategories
                    }
                }
                this.setSpiritSelectedFilter(filterName, selectedId)
                this.page = 0
                this.fetchSpirits()
            },
            setSpiritSelectedFilter(filterName, selectedId) {
                this.filters.forEach((item, index) => {
                    if (item.field === filterName) {
                        this.filters[index].selectedId = selectedId
                    }
                })
            },
            fetchSpirits() {
                this.fetchingSpirits = true
                const pageSize = 30

                const filterObject = {
                    published: true
                }

                if (this.cancelToken.cancel) {
                    this.cancelToken.cancel('')
                }

                this.filters.forEach(filter => {
                    if (filter.selectedId !== null && filter.selectedId !== 0) {
                        filterObject[filter.field] = filter.selectedId
                    }
                })

                return spiritService.getAll({ filter: filterObject, sort: ['rank', 'ASC'], range: [this.page, pageSize], applyClustering: true })
                    .then(response => {
                        const spirits = (this.page === 0) ? randomizeItems(response.content) : joinWines(this.spirits, randomizeItems(response.content))
                        this.page++
                        this.spirits = spirits
                        this.fetchingSpirits = false
                    })
            },
            viewSpirit(spirit) {
                this.$router.push({
                    path: `/spirit-mastery/${spirit.id}`
                })
            },
            extractSubCategoryName(name) {
                return name.split(' - ')[1] ? name.split(' - ')[1] : name
            },
            clearFilters() {
                this.filters.forEach((item, index) => {
                    this.filters[index].selectedId = 0
                })
                this.page = 0
                this.fetchSpirits()
            },
            scrollEvent(e) {
                const element = e.target
                const maxScroll = element.scrollHeight - element.clientHeight
                if (element.scrollTop >= maxScroll - 700 && !this.fetchingSpirits) {
                    this.fetchSpirits()
                }
            }
        }
    }
</script>

<style scoped>
.list-background {
  position: relative;
  background: url('~@/assets/spirit-mastery/background_spirit_info.png');
  width: 100%;
}
.header-bar-container {
    padding: 0 1rem;
}
.toggler {
    background: #7e2d2b;
    color: #fff;
    border: 1px solid #7e2d2b;
    font-family: CFALight;
    font-size: 1.2rem;
    padding: 0.25rem 1.2rem;
}
.filter-header {
  font-family: CFABold;
  font-size: 3rem;
}
.filters-container {
  background: #7e2d2b;
  color: #e8e5e4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  filter: drop-shadow(0px 3px 9px rgba(0,0,0,0.35));
}
.filters-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clear-filters {
  font-family: CFABold;
  padding: 7px 10px;
  font-size: 1.3rem;
  cursor: pointer;
  margin-right: 10px;
}
.close-icon {
  color: #cfab6f;
}

.filters-bottom-container {
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.no-products {
    padding-top: 2rem;
    text-align: center;
    font-size: 35px;
    font-weight:600;
    margin-top: 18%;
}
.spirit-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 50px;
  height: 64.9vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  align-content: flex-start;
}
</style>
